import React from 'react';
import { formatDistanceToNow, format, subHours } from 'date-fns';
import { Article } from '../../types/Article';
import { ArrowUpRight } from 'lucide-react';
import { motion } from 'framer-motion';

interface NewsCardProps {
    item: Article;
}

const NewsCard: React.FC<NewsCardProps> = ({ item }) => {
    const formatDate = (timestamp: string) => {
        const date = new Date(timestamp);
        const twentyFourHoursAgo = subHours(new Date(), 24);
        if (date >= twentyFourHoursAgo) {
            return formatDistanceToNow(date, { addSuffix: true });
        }
        return format(date, 'MMM dd, yyyy');
    };

    const getScoreColor = (value: number) => {
        if (value >= 7) return 'bg-green-200/50 dark:bg-green-200/50 text-myColor-800 dark:text-zinc-300';
        if (value >= 4) return 'bg-yellow-200/50 dark:bg-yellow-200/50 text-myColor-800 dark:text-zinc-300';
        return 'bg-red-200/50 dark:bg-red-200/50 text-myColor-800 dark:text-zinc-300';
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="flex flex-col h-full rounded-xl overflow-hidden
                     bg-white/50 dark:bg-zinc-800/50
                     border border-myColor-400/50 dark:border-zinc-700/50
                     shadow-sm hover:shadow-md transition-all duration-300"
        >
            {/* Image */}
            <div className="relative aspect-video overflow-hidden">
                <img
                    src={item.image_url || '/og.jpg'}
                    alt={item.title}
                    className="w-full h-full object-cover"
                    onError={(e) => {
                        e.currentTarget.src = '/og.jpg';
                    }}
                />
            </div>

            {/* Content */}
            <div className="flex flex-col flex-grow p-4">
                {/* Date and Tags */}
                <div className="flex items-center flex-wrap gap-2 mb-2">
                    <span className="text-sm text-myColor-700 dark:text-zinc-400">
                        {formatDate(item.published_at)}
                    </span>
                    <div className="flex flex-wrap gap-1">
                        {Array.isArray(item.tags) && item.tags.map((tag) => (
                            <span
                                key={tag.id}
                                className="px-2 py-0.5 text-xs rounded-full
                                         bg-myColor-200/70 dark:bg-zinc-700/70
                                         text-myColor-800 dark:text-zinc-300"
                            >
                                {tag.name}
                            </span>
                        ))}
                    </div>
                </div>

                {/* Title */}
                <motion.a 
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex-grow"
                    whileHover={{ x: 1 }}
                >
                    <h3 className="font-semibold text-lg text-myColor-900 dark:text-zinc-100 mb-2">
                        {item.title}
                    </h3>
                </motion.a>

                {/* Source and Scores */}
                <div className="flex items-center gap-2 mt-2 flex-wrap">
                    <motion.a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-1 text-sm
                                 text-myColor-800 dark:text-zinc-300
                                 hover:text-myColor-900 dark:hover:text-zinc-100
                                 transition-colors duration-300"
                        whileHover={{ x: 1 }}
                    >
                        {item.publisher?.name || 'Unknown Source'}
                        <ArrowUpRight size={14} />
                    </motion.a>

                    {item.freshness_score !== null && (
                        <span className={`px-2 py-0.5 text-xs rounded-full ${getScoreColor(item.freshness_score)}`}>
                            F: {item.freshness_score}
                        </span>
                    )}
                    
                    {item.sentiment_score !== null && (
                        <span className={`px-2 py-0.5 text-xs rounded-full ${getScoreColor(item.sentiment_score)}`}>
                            S: {item.sentiment_score}
                        </span>
                    )}
                </div>
            </div>
        </motion.div>
    );
};

export default NewsCard; 