import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';

interface Coin {
    id: number;
    symbol: string;
    name: string;
    source_type: string;
    source_identifier: string;
    is_active: boolean;
    last_update?: string;
}

type SourceType = 'coincodex';

const AVAILABLE_SOURCES: { [key in SourceType]: string } = {
    'coincodex': 'CoinCodex'
};

interface CoinCodexResponse {
    symbol: string;
    coin_name: string;
    price_high_24_usd: number;
    price_low_24_usd: number;
    last_price_usd: number;
    volume_24_usd: number;
    total_supply: string;
    supply: number;
    ath_usd: number;
    ath_date: string;
    market_cap_rank: number;
}

interface EditingCoin {
    id: number;
    symbol: string;
    name: string;
    source_identifier: string;
}

const CryptoManagement: React.FC = () => {
    const { api } = useAuth();
    const [coins, setCoins] = useState<Coin[]>([]);
    const [newCoin, setNewCoin] = useState({
        symbol: '',
        name: '',
        source_type: 'coincodex' as SourceType,
        source_identifier: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [bulkSymbols, setBulkSymbols] = useState('');
    const [bulkLoading, setBulkLoading] = useState(false);
    const [processingStatus, setProcessingStatus] = useState<string[]>([]);
    const [editingCoin, setEditingCoin] = useState<EditingCoin | null>(null);

    const fetchCoins = async () => {
        try {
            const response = await api.get('/api/crypto/coins');
            setCoins(response.data);
        } catch (error) {
            console.error('Failed to fetch coins:', error);
            setError('Failed to fetch coins');
        }
    };

    useEffect(() => {
        fetchCoins();
    }, []);

    const handleAddCoin = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');
        
        try {
            await api.post('/api/crypto/coins', {
                symbol: newCoin.symbol.toUpperCase(),
                name: newCoin.name,
                source_type: newCoin.source_type,
                source_identifier: newCoin.source_identifier.toLowerCase() || newCoin.symbol.toLowerCase()
            });
            
            setNewCoin({ 
                symbol: '', 
                name: '', 
                source_type: 'coincodex' as SourceType,
                source_identifier: '' 
            });
            setSuccess('Coin added successfully');
            fetchCoins();
        } catch (error) {
            let errorMessage = 'Failed to add coin';
            if (axios.isAxiosError(error) && error.response) {
                errorMessage = error.response.data.error || error.message;
            }
            setError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateCoin = async (id: number) => {
        try {
            await api.post(`/api/crypto/coins/${id}/update`);
            setSuccess('Coin data updated successfully');
            fetchCoins();
        } catch (error) {
            console.error('Failed to update coin:', error);
            setError('Failed to update coin');
        }
    };

    const handleDelete = async (id: number) => {
        if (!window.confirm('Are you sure you want to delete this coin?')) {
            return;
        }
        
        try {
            await api.delete(`/api/crypto/coins/${id}`);
            setSuccess('Coin deleted successfully');
            fetchCoins();
        } catch (error) {
            console.error('Failed to delete coin:', error);
            setError('Failed to delete coin');
        }
    };

    const handleBulkImport = async (e: React.FormEvent) => {
        e.preventDefault();
        setBulkLoading(true);
        setProcessingStatus([]);
        setError('');
        setSuccess('');
        
        const symbols = bulkSymbols
            .split(',')
            .map(s => s.trim().toUpperCase())
            .filter(s => s);

        try {
            for (const symbol of symbols) {
                setProcessingStatus(prev => [...prev, `Processing ${symbol}...`]);
                
                try {
                    await api.post('/api/crypto/coins', {
                        symbol: symbol,
                        name: '', // Backend will fetch from API
                        source_type: 'coincodex',
                        source_identifier: symbol.toLowerCase()
                    });

                    setProcessingStatus(prev => [...prev, `✅ Added ${symbol}`]);
                } catch (error) {
                    let errorMessage = 'Unknown error occurred';
                    
                    if (axios.isAxiosError(error) && error.response) {
                        errorMessage = error.response.data.error || error.message;
                        
                        if (errorMessage.includes('already exists')) {
                            setProcessingStatus(prev => [...prev, `ℹ️ Skipped ${symbol}: Already exists`]);
                            continue;
                        }
                    }
                    
                    setProcessingStatus(prev => [...prev, `❌ Failed to add ${symbol}: ${errorMessage}`]);
                }
            }

            setBulkSymbols('');
            fetchCoins();
        } catch (error) {
            const errorMessage = error instanceof Error 
                ? error.message 
                : 'Unknown error occurred';
            setError(`Bulk import failed: ${errorMessage}`);
        } finally {
            setBulkLoading(false);
        }
    };

    const handleEdit = (coin: Coin) => {
        setEditingCoin({
            id: coin.id,
            symbol: coin.symbol,
            name: coin.name,
            source_identifier: coin.source_identifier
        });
    };

    const handleSaveEdit = async () => {
        if (!editingCoin) return;
        
        try {
            await api.put(`/api/crypto/coins/${editingCoin.id}`, {
                symbol: editingCoin.symbol.toUpperCase(),
                name: editingCoin.name,
                source_identifier: editingCoin.source_identifier.toLowerCase()
            });
            
            setSuccess('Coin updated successfully');
            setEditingCoin(null);
            fetchCoins();
        } catch (error) {
            let errorMessage = 'Failed to update coin';
            if (axios.isAxiosError(error) && error.response) {
                errorMessage = error.response.data.error || error.message;
            }
            setError(errorMessage);
        }
    };

    const handleCancelEdit = () => {
        setEditingCoin(null);
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-6">Crypto Data Management</h1>

            {error && (
                <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
                    {error}
                </div>
            )}

            {success && (
                <div className="mb-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded">
                    {success}
                </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <form onSubmit={handleAddCoin} className="space-y-4">
                    <h2 className="text-lg font-semibold">Add Single Coin</h2>
                    <div className="grid grid-cols-4 gap-4">
                        <input
                            type="text"
                            value={newCoin.symbol}
                            onChange={(e) => setNewCoin({...newCoin, symbol: e.target.value.toUpperCase()})}
                            placeholder="Coin Symbol (e.g. BTC)"
                            className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            required
                        />
                        <input
                            type="text"
                            value={newCoin.name}
                            onChange={(e) => setNewCoin({...newCoin, name: e.target.value})}
                            placeholder="Coin Name (e.g. Bitcoin)"
                            className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            required
                        />
                        <select
                            value={newCoin.source_type}
                            onChange={(e) => setNewCoin({
                                ...newCoin, 
                                source_type: e.target.value as SourceType,
                                source_identifier: '' // Reset source_identifier when changing source
                            })}
                            className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        >
                            {Object.entries(AVAILABLE_SOURCES).map(([value, label]) => (
                                <option key={value} value={value}>
                                    {label}
                                </option>
                            ))}
                        </select>
                        {newCoin.source_type === 'coincodex' && (
                            <input
                                type="text"
                                value={newCoin.source_identifier}
                                onChange={(e) => setNewCoin({...newCoin, source_identifier: e.target.value})}
                                placeholder="Source ID (optional, defaults to symbol)"
                                className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            />
                        )}
                    </div>
                    <button
                        type="submit"
                        disabled={loading}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
                    >
                        {loading ? 'Adding...' : 'Add Coin'}
                    </button>
                </form>

                <form onSubmit={handleBulkImport} className="space-y-4">
                    <h2 className="text-lg font-semibold">Bulk Import</h2>
                    <div>
                        <textarea
                            value={bulkSymbols}
                            onChange={(e) => setBulkSymbols(e.target.value)}
                            placeholder="Enter coin symbols separated by commas (e.g., BTC, ETH, XRP)"
                            className="w-full h-32 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        disabled={bulkLoading}
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 disabled:bg-green-300"
                    >
                        {bulkLoading ? 'Importing...' : 'Bulk Import'}
                    </button>
                </form>
            </div>

            {processingStatus.length > 0 && (
                <div className="mb-6 bg-gray-50 p-4 rounded-lg">
                    <h3 className="font-semibold mb-2">Import Progress:</h3>
                    <div className="space-y-1">
                        {processingStatus.map((status, index) => (
                            <div key={index} className="text-sm">
                                {status}
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <div className="bg-white rounded-lg shadow overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Symbol</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Source</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Source ID</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Update</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {coins.map(coin => (
                            <tr key={coin.id}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {editingCoin?.id === coin.id ? (
                                        <input
                                            type="text"
                                            value={editingCoin.symbol}
                                            onChange={(e) => setEditingCoin({
                                                ...editingCoin,
                                                symbol: e.target.value.toUpperCase()
                                            })}
                                            className="rounded-md border-gray-300 shadow-sm w-20"
                                        />
                                    ) : coin.symbol}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {editingCoin?.id === coin.id ? (
                                        <input
                                            type="text"
                                            value={editingCoin.name}
                                            onChange={(e) => setEditingCoin({
                                                ...editingCoin,
                                                name: e.target.value
                                            })}
                                            className="rounded-md border-gray-300 shadow-sm w-40"
                                        />
                                    ) : coin.name}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {AVAILABLE_SOURCES[coin.source_type as SourceType]}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {editingCoin?.id === coin.id ? (
                                        <input
                                            type="text"
                                            value={editingCoin.source_identifier}
                                            onChange={(e) => setEditingCoin({
                                                ...editingCoin,
                                                source_identifier: e.target.value.toLowerCase()
                                            })}
                                            className="rounded-md border-gray-300 shadow-sm w-32"
                                        />
                                    ) : coin.source_identifier}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {coin.last_update ? new Date(coin.last_update).toLocaleString() : 'Never'}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="flex space-x-2">
                                        {editingCoin?.id === coin.id ? (
                                            <>
                                                <button
                                                    type="button"
                                                    onClick={handleSaveEdit}
                                                    className="text-green-600 hover:text-green-900"
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={handleCancelEdit}
                                                    className="text-gray-600 hover:text-gray-900"
                                                >
                                                    Cancel
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button
                                                    type="button"
                                                    onClick={() => handleEdit(coin)}
                                                    className="text-blue-600 hover:text-blue-900"
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => handleUpdateCoin(coin.id)}
                                                    className="text-indigo-600 hover:text-indigo-900"
                                                >
                                                    Update
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => handleDelete(coin.id)}
                                                    className="text-red-600 hover:text-red-900"
                                                >
                                                    Delete
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CryptoManagement; 