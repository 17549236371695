import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

interface Article {
    id: number;
    title: string;
    url: string;
    published_at: string;
    featured: boolean;
    publisher_name: string;
    image_url: string | null;
}

interface ArticlesResponse {
    articles: Article[];
    hasMore: boolean;
    nextPage: number;
    totalCount: number;
}

const ArticleManagement: React.FC = () => {
    const { api } = useAuth();
    const [articles, setArticles] = useState<Article[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchArticles();
    }, []);

    const fetchArticles = async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await api.get<ArticlesResponse>('/api/articles/admin');
            
            // Check if response.data has the articles property
            if (response.data && Array.isArray(response.data.articles)) {
                setArticles(response.data.articles);
            } else if (Array.isArray(response.data)) {
                // Handle case where response might be just an array
                setArticles(response.data);
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            console.error('Failed to fetch articles:', error);
            setError('Failed to load articles');
            setArticles([]); // Set empty array on error
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <div className="p-6">Loading...</div>;
    if (error) return <div className="p-6 text-red-500">{error}</div>;
    if (!articles.length) return <div className="p-6">No articles found</div>;

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-6">Articles Grid View</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {articles.map(article => (
                    <div key={article.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                        {article.image_url && (
                            <img 
                                src={article.image_url} 
                                alt={article.title}
                                className="w-full h-48 object-cover"
                                onError={(e) => {
                                    const target = e.target as HTMLImageElement;
                                    target.style.display = 'none';
                                }}
                            />
                        )}
                        <div className="p-4">
                            <h2 className="text-xl font-semibold mb-2">
                                <a 
                                    href={article.url} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="text-blue-600 hover:text-blue-800"
                                >
                                    {article.title}
                                </a>
                            </h2>
                            <div className="text-sm text-gray-600">
                                <p>Publisher: {article.publisher_name}</p>
                                <p>Published: {new Date(article.published_at).toLocaleDateString()}</p>
                            </div>
                            {article.featured && (
                                <span className="mt-2 inline-block bg-green-100 text-green-800 text-xs px-2 py-1 rounded-full">
                                    Featured
                                </span>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ArticleManagement;