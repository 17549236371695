import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import NewsTable from '../news/NewsTable';
import MarketOverview from '../widgets/MarketOverview';
import SEOHead from '../SEO/SEOHead';
import NotFound from './NotFound';
import type { Category } from '../../types/Category';
import type { Article } from '../../types/Article';

const CategoryPage: React.FC = () => {
    const { slug } = useParams<{ slug: string }>();
    const { api } = useAuth();
    const [category, setCategory] = useState<Category | null>(null);
    const [news, setNews] = useState<Article[]>([]);
    const [loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [filters, setFilters] = useState({
        publisher: '',
        tag: '',
        freshness_score: '',
        sentiment_score: ''
    });

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                const response = await api.get(`/api/categories/${slug}`);
                const categoryData = response.data;
                
                if (!categoryData.isActive) {
                    setNotFound(true);
                    setLoading(false);
                    return;
                }
                
                setCategory(categoryData);
                
                // Fetch all tags and find the matching one
                const tagsResponse = await api.get('/api/tags/public');
                const tags = tagsResponse.data;
                const matchingTag = tags.find((tag: any) => tag.id === categoryData.tagId);
                
                if (matchingTag) {
                    setFilters(prev => ({
                        ...prev,
                        tag: matchingTag.name
                    }));
                }
            } catch (error: any) {
                if (error.response?.status === 404) {
                    setNotFound(true);
                }
                console.error('Failed to fetch category:', error);
            } finally {
                setLoading(false);
            }
        };

        if (slug) {
            fetchCategory();
        }
    }, [slug, api]);

    useEffect(() => {
        if (category && filters.tag) {
            fetchNews(1);
        }
    }, [category, filters.tag]);

    const fetchNews = async (pageNum: number) => {
        try {
            setLoading(true);
            const response = await api.get('/api/articles/public', {
                params: {
                    page: pageNum,
                    limit: 20,
                    ...filters
                }
            });
            
            if (pageNum === 1) {
                setNews(response.data.articles);
            } else {
                setNews(prev => [...prev, ...response.data.articles]);
            }
            setHasMore(response.data.hasMore);
            setPage(pageNum);
        } catch (error) {
            console.error('Failed to fetch news:', error);
            setNews([]);
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (key: string, value: string) => {
        const newFilters = {
            ...filters,
            [key]: value
        };
        setFilters(newFilters);
        setPage(1);
        fetchNews(1);
    };

    const handleLoadMore = () => {
        if (!loading && hasMore) {
            fetchNews(page + 1);
        }
    };

    if (loading) {
        return <div className="p-6">Loading...</div>;
    }

    if (notFound) {
        return <NotFound />;
    }

    if (!category) {
        return <NotFound />;
    }

    return (
        <>
            <SEOHead
                title={category.metaTitle || `${category.name} - Your Site Name`}
                description={category.metaDescription || category.description || `Latest ${category.name} news and updates`}
                canonical={`/${category.slug}`}
            />
            
            <div className="space-y-6">
                <div className="bg-white dark:bg-zinc-800 rounded-lg shadow p-6">
                    <h1 className="text-2xl font-bold mb-2">{category.name}</h1>
                    {category.description && (
                        <p className="text-gray-600 dark:text-gray-300">
                            {category.description}
                        </p>
                    )}
                </div>

                <div className="bg-white dark:bg-zinc-800 rounded-lg shadow">
                    <div className="p-6">
                        <NewsTable 
                            news={news} 
                            loading={loading}
                            hasMore={hasMore}
                            onLoadMore={handleLoadMore}
                            filters={filters}
                            onFilterChange={handleFilterChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CategoryPage; 