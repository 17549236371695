import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import PublicLayout from './components/layouts/PublicLayout';
import HomePage from './components/pages/HomePage';
import PublisherManagement from './components/PublisherManagement';
import Dashboard from './components/Dashboard';
import ArticleManagement from './components/ArticleManagement';
import ArticlesManagement from './components/ArticlesManagement';
import TagManagement from './components/TagManagement';
import Login from './components/Login';
import ProtectedLayout from './components/ProtectedLayout';
import UserManagement from './components/UserManagement';
import ArticleCards from './components/ArticleCards';
import ActivityLogs from './components/ActivityLogs';
import CryptoManagement from './components/CryptoManagement';
import CategoryPage from './components/pages/CategoryPage';
import CategoryManagement from './components/CategoryManagement';
import { HelmetProvider } from 'react-helmet-async';
import NotFound from './components/pages/NotFound';
import PagesManagement from './components/PagesManagement';
import APILog from './components/APIUsage';

const App: React.FC = () => {
    const navigation = [
        { name: 'Articles', href: '/articles' },
        { 
            name: 'Articles Management', 
            href: '/admin/articles_management',
            requiresAdmin: true 
        },
        { 
            name: 'Categories', 
            href: '/admin/categories',
            requiresAdmin: true 
        },
        { 
            name: 'AI Usage',
            href: '/admin/ai-usage',
            requiresAdmin: true 
        }
    ]

    return (
        <HelmetProvider>
            <ThemeProvider>
                <div className="App">
                    <Routes>
                        <Route element={<PublicLayout />}>
                            <Route index element={<HomePage />} />
                            <Route path="/news" element={<HomePage />} />
                            <Route path="/articles" element={<ArticleCards />} />
                            <Route path="/:slug" element={<CategoryPage />} />
                            <Route path="*" element={<NotFound />} />
                        </Route>
                        <Route path="/login" element={<Login />} />
                        <Route path="/admin" element={<ProtectedLayout navigation={navigation} />}>
                            <Route index element={<Dashboard />} />
                            <Route path="publishers" element={<PublisherManagement />} />
                            <Route path="articles" element={<ArticleManagement />} />
                            <Route path="articles_management" element={<ArticlesManagement />} />
                            <Route path="tags" element={<TagManagement />} />
                            <Route path="users" element={<UserManagement />} />
                            <Route path="logs" element={<ActivityLogs />} />
                            <Route path="ai-usage" element={<APILog />} />
                            <Route path="crypto" element={<CryptoManagement />} />
                            <Route path="categories" element={<CategoryManagement />} />
                            <Route path="pages" element={<PagesManagement />} />
                        </Route>
                    </Routes>
                </div>
            </ThemeProvider>
        </HelmetProvider>
    );
};

export default App;
