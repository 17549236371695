import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOHeadProps {
    title: string;
    description?: string;
    canonical?: string;
    image?: string;
}

const SEOHead: React.FC<SEOHeadProps> = ({ 
    title, 
    description, 
    canonical,
    image = '/og.jpg'
}) => {
    // Force the value to be exactly what's in the environment
    const siteUrl = process.env.REACT_APP_BASE_URL;
    
    // Debug logging
    console.log('Environment Variables:', {
        REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
        NODE_ENV: process.env.NODE_ENV
    });
    
    if (!siteUrl) {
        console.warn('REACT_APP_BASE_URL is not set!');
    }
    
    // Only construct fullImageUrl if image is provided
    const fullImageUrl = image ? (image.startsWith('http') ? image : `${siteUrl}${image}`) : `${siteUrl}/og.jpg`;
    const canonicalUrl = canonical ? `${siteUrl}${canonical}` : siteUrl;
    
    console.log('Generated URLs:', {
        siteUrl,
        fullImageUrl,
        canonicalUrl
    });
    
    return (
        <Helmet>
            <title>{title}</title>
            {description && <meta name="description" content={description} />}
            {canonical && <link rel="canonical" href={canonicalUrl} />}
            
            {/* Open Graph / Facebook */}
            <meta property="og:type" content="article" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description || `Read the latest ${title}`} />
            <meta property="og:image" content={fullImageUrl} />
            {canonical && <meta property="og:url" content={canonicalUrl} />}
            
            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description || `Read the latest ${title}`} />
            <meta name="twitter:image" content={fullImageUrl} />
        </Helmet>
    );
};

export default SEOHead; 