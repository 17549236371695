import React from 'react';

const NotFound: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-[50vh]">
      <h1 className="text-4xl font-bold text-gray-800 dark:text-gray-200">
        404 - Page doesn't exist
      </h1>
    </div>
  );
};

export default NotFound; 