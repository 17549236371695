import React from 'react';
import { X } from 'lucide-react';
import { motion } from 'framer-motion';

interface FilterCardProps {
    label: string;
    value: string | boolean;
    onRemove: () => void;
}

const FilterCard: React.FC<FilterCardProps> = ({ label, value, onRemove }) => {
    const displayValue = typeof value === 'boolean' ? 'Yes' : value;
    
    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="flex items-center gap-2 px-3 py-2.5 rounded-lg
                     bg-myColor-100/50 dark:bg-zinc-800/50
                     border border-myColor-400/50 dark:border-zinc-700/50
                     text-sm text-myColor-800 dark:text-zinc-300"
        >
            <span>{label}: {displayValue}</span>
            <button
                onClick={onRemove}
                className="p-0.5 rounded-full hover:bg-myColor-200/50 dark:hover:bg-zinc-700/50
                         text-myColor-600 dark:text-zinc-400
                         transition-colors duration-200"
            >
                <X className="w-3 h-3" />
            </button>
        </motion.div>
    );
};

export default FilterCard; 