import React, { useRef, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from "framer-motion";
import {
    LayoutGrid, Palette, BookOpen, Home, Users, 
    Briefcase, Mail, Compass, CreditCard, Settings, 
    UserCircle, LogOut, Sun, Moon, Menu
} from "lucide-react";
import { useTheme } from '../../context/ThemeContext';
import { useMediaQuery } from 'react-responsive';
import { useAuth } from '../../contexts/AuthContext';
import type { Category } from '../../types/Category';

type MenuItem = {
    icon: React.ReactNode;
    label: string;
};

type SubMenuItem = {
    icon: React.ReactNode;
    title: string;
    path?: string;
};

type MenuItemContent = {
    items: SubMenuItem[];
};

const menuItems: MenuItem[] = [
    {
        icon: <BookOpen className="w-5 h-5" />,
        label: "News",
    },
    {
        icon: <Palette className="w-5 h-5" />,
        label: "Resources",
    },
    {
        icon: <LayoutGrid className="w-5 h-5" />,
        label: "Account",
    },
];

const menuItemsContent = (categories: Category[]): MenuItemContent[] => [
    {
        items: [
            { title: "All News", icon: <Home size={18} />, path: "/" },
            ...categories.map(category => ({
                title: category.name,
                icon: <BookOpen size={18} />,
                path: `/${category.slug}`
            }))
        ],
    },
    {
        items: [
            { title: "Services", icon: <Briefcase size={18} /> },
            { title: "Contact", icon: <Mail size={18} /> },
            { title: "Explore", icon: <Compass size={18} /> },
            { title: "Pricing", icon: <CreditCard size={18} /> },
        ],
    },
    {
        items: [
            { title: "Settings", icon: <Settings size={18} /> },
            { title: "Profile", icon: <UserCircle size={18} /> },
            { title: "Logout", icon: <LogOut size={18} /> },
        ],
    },
];

const Navbar: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [isThemeHovered, setIsThemeHovered] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [expandedMobileSection, setExpandedMobileSection] = useState<number | null>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const timeoutRef = useRef<NodeJS.Timeout>();
    const { theme, setTheme } = useTheme();
    const { api } = useAuth();
    const [categories, setCategories] = useState<Category[]>([]);
    
    const isMobile = useMediaQuery({ maxWidth: 768 });

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await api.get('/api/categories');
                setCategories(response.data.filter((cat: Category) => cat.isActive));
            } catch (error) {
                console.error('Failed to fetch categories:', error);
            }
        };

        fetchCategories();
    }, [api]);

    const handleMouseEnter = (index: number | null) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        setActiveIndex(index);
        setIsThemeHovered(false);
    };

    const handleMouseLeave = () => {
        if (!menuRef.current?.matches(':hover')) {
            timeoutRef.current = setTimeout(() => {
                setActiveIndex(null);
                setIsThemeHovered(false);
            }, 150);
        }
    };

    const getMobileMenuItems = () => {
        const content = menuItemsContent(categories);
        if (activeIndex !== null && activeIndex >= 0 && activeIndex < content.length) {
            return content[activeIndex].items;
        }
        return [];
    };

    const renderMenuItem = (item: SubMenuItem, idx: number) => (
        <Link
            key={idx}
            to={item.path || '#'}
            className="w-full group flex items-center gap-2 cursor-pointer hover:bg-myColor-600/50 dark:hover:bg-zinc-800/50 py-3 px-4 text-myColor-700 hover:text-myColor-900 dark:text-zinc-400 dark:hover:text-zinc-100 duration-100 rounded-[12px]"
            onClick={() => {
                setActiveIndex(null);
                if (isMobile) {
                    setIsMobileMenuOpen(false);
                    setExpandedMobileSection(null);
                }
            }}
        >
            <div className="text-myColor-600 group-hover:text-myColor-800 duration-100 dark:text-zinc-500 dark:group-hover:text-zinc-400">
                {item.icon}
            </div>
            <p className="font-medium text-sm group-hover:pl-1 duration-300 transition-[padding]">
                {item.title}
            </p>
        </Link>
    );

    // Calculate dropdown height based on number of items
    const getDropdownHeight = (index: number | null): number => {
        if (index === null) return 48; // Default height when closed
        
        const content = menuItemsContent(categories);
        if (!content[index]) return 48;
        
        const itemCount = content[index].items.length;
        const itemHeight = 56; // Height of each menu item
        const padding = 16; // Total vertical padding
        
        return Math.min(itemCount * itemHeight + padding, 400); // Max height of 400px
    };

    return (
        <nav className="relative z-50 px-4">
            <div className="container mx-auto px-6">
                <div className="flex justify-between items-center h-16">
                    <Link to="/" className="logo text-xl text-myColor-900 dark:text-zinc-100">
                        Coinscoop
                    </Link>

                    {/* Hide main navigation on mobile */}
                    <div className="relative hidden md:flex items-center mr-6">
                        <motion.div 
                            className="flex items-center justify-between gap-0 bg-transparent w-[460px] relative z-10"
                            onMouseLeave={handleMouseLeave}
                        >
                            {menuItems.map((item, index) => (
                                <motion.button
                                    key={index}
                                    className="flex relative items-center justify-center gap-2 duration-300 transition-colors py-3 px-4 text-myColor-800 hover:text-myColor-900 dark:text-zinc-500 dark:hover:text-zinc-100"
                                    onMouseEnter={() => handleMouseEnter(index)}
                                >
                                    <div className="flex relative items-center justify-center gap-3 z-[2]">
                                        {item.icon}
                                        <span className="font-medium text-sm">{item.label}</span>
                                    </div>
                                    {activeIndex === index && (
                                        <motion.div
                                            className="absolute inset-0 size-full bg-myColor-600/20 dark:bg-zinc-800/50 border-[1.5px] border-myColor-600/80 dark:border-zinc-800"
                                            layoutId="indicator"
                                            style={{ borderRadius: 20 }}
                                            initial={false}
                                        />
                                    )}
                                </motion.button>
                            ))}
                            
                            <motion.button
                                onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                                onMouseEnter={() => {
                                    handleMouseEnter(null);
                                    setIsThemeHovered(true);
                                }}
                                className="flex relative items-center justify-center gap-2 duration-300 transition-colors py-3 px-4 text-myColor-800 hover:text-myColor-900 dark:text-zinc-500 dark:hover:text-zinc-100 cursor-pointer"
                            >
                                <div className="flex relative items-center justify-center z-[2]">
                                    {theme === 'dark' ? 
                                        <Sun className="w-5 h-5" /> : 
                                        <Moon className="w-5 h-5" />
                                    }
                                </div>
                                {isThemeHovered && (
                                    <motion.div
                                        className="absolute inset-0 size-full bg-myColor-200/50 dark:bg-zinc-800/50 border-[1.5px] border-myColor-400 dark:border-zinc-800"
                                        layoutId="indicator"
                                        style={{ borderRadius: 20 }}
                                        initial={false}
                                    />
                                )}
                            </motion.button>
                        </motion.div>

                        <div 
                            className="absolute z-0" 
                            style={{
                                left: '-3%',
                                top: '-3%'
                            }}
                        >
                            <motion.div
                                ref={menuRef}
                                className="bg-myColor-400/20 dark:bg-zinc-900/50 backdrop-blur-xl overflow-hidden border-[1.5px] border-myColor-600/80 dark:border-zinc-800"
                                style={{ 
                                    borderRadius: 20,
                                    transformOrigin: 'top',
                                    position: 'absolute',
                                    top: '0',
                                }}
                                animate={{
                                    width: 500,
                                    height: getDropdownHeight(activeIndex),
                                    y: activeIndex !== null ? 0 : 0,
                                }}
                                transition={{
                                    type: "spring",
                                    stiffness: 1000,
                                    damping: 60,
                                }}
                                onMouseEnter={() => {
                                    if (timeoutRef.current) {
                                        clearTimeout(timeoutRef.current);
                                    }
                                }}
                                onMouseLeave={handleMouseLeave}
                            >
                                <AnimatePresence initial={false} mode="wait">
                                    {activeIndex !== null && (
                                        <motion.div
                                            key={activeIndex}
                                            className="pb-0 flex items-start justify-start absolute w-full overflow-y-auto max-h-[400px] scrollbar-thin scrollbar-thumb-myColor-600/50 dark:scrollbar-thumb-zinc-700"
                                            style={{ 
                                                top: '48px',
                                            }}
                                            initial={{ opacity: 0, y: 0 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: 0 }}
                                            transition={{ duration: 0.1 }}
                                        >
                                            <div className="w-full px-2">
                                                {menuItemsContent(categories)[activeIndex]?.items.map((item, idx) => 
                                                    renderMenuItem(item, idx)
                                                )}
                                            </div>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </motion.div>
                        </div>
                    </div>

                    {/* Mobile Menu Button */}
                    <div className="flex md:hidden items-center gap-2">
                        <motion.button
                            onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                            className="flex relative items-center justify-center p-2 text-zinc-500 hover:text-zinc-100"
                        >
                            {theme === 'dark' ? 
                                <Sun className="w-5 h-5" /> : 
                                <Moon className="w-5 h-5" />
                            }
                        </motion.button>

                        <motion.button
                            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                            className="flex relative items-center justify-center p-2 text-zinc-500 hover:text-zinc-100"
                        >
                            <Menu className="w-6 h-6" />
                            {isMobileMenuOpen && (
                                <motion.div
                                    className="absolute inset-0 size-full bg-zinc-800/50 border border-zinc-800"
                                    layoutId="mobileIndicator"
                                    style={{ borderRadius: 12 }}
                                    initial={false}
                                />
                            )}
                        </motion.button>
                    </div>
                </div>

                {/* Updated Mobile Menu Dropdown */}
                <AnimatePresence>
                    {isMobileMenuOpen && (
                        <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: 'auto', opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className="md:hidden overflow-hidden"
                        >
                            <div className="py-2 px-4 space-y-1">
                                {menuItems.map((item, index) => (
                                    <div key={index}>
                                        <motion.button
                                            className="flex items-center w-full gap-3 p-3 text-zinc-500 hover:text-zinc-100 rounded-lg hover:bg-zinc-800/50"
                                            onClick={() => {
                                                setExpandedMobileSection(expandedMobileSection === index ? null : index);
                                            }}
                                        >
                                            {item.icon}
                                            <span className="font-medium text-sm">{item.label}</span>
                                        </motion.button>
                                        
                                        <AnimatePresence>
                                            {expandedMobileSection === index && (
                                                <motion.div
                                                    initial={{ height: 0, opacity: 0 }}
                                                    animate={{ height: 'auto', opacity: 1 }}
                                                    exit={{ height: 0, opacity: 0 }}
                                                    transition={{ duration: 0.2 }}
                                                >
                                                    {menuItemsContent(categories)[index].items.map((subItem, idx) => (
                                                        <Link
                                                            key={`submenu-${idx}`}
                                                            to={subItem.path || '#'}
                                                            className="flex items-center w-full gap-3 p-3 pl-8 text-zinc-400 hover:text-zinc-100 rounded-lg hover:bg-zinc-800/50"
                                                            onClick={() => {
                                                                setIsMobileMenuOpen(false);
                                                                setExpandedMobileSection(null);
                                                            }}
                                                        >
                                                            {subItem.icon}
                                                            <span className="font-medium text-sm">{subItem.title}</span>
                                                        </Link>
                                                    ))}
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                    </div>
                                ))}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </nav>
    );
};

export default Navbar; 