import React, { useState } from 'react';
import { formatDistanceToNow, format, subHours } from 'date-fns';
import { Article } from '../../types/Article';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowUpRight, ChevronDown, ChevronUp } from 'lucide-react';
// import NewsFilters from './NewsFilters';

interface NewsTableProps {
    news: Article[];
    loading: boolean;
    onLoadMore: () => void;
    hasMore: boolean;
    filters: {
        publisher?: string;
        tag?: string;
        dateFrom?: string;
        dateTo?: string;
        freshness_score?: string;
        sentiment_score?: string;
    };
    onFilterChange: (key: string, value: string) => void;
}

interface ExpandedState {
    [key: number]: boolean;
}

const NewsTable: React.FC<NewsTableProps> = ({ news, loading, onLoadMore, hasMore, filters, onFilterChange }) => {
    const [expandedItems, setExpandedItems] = useState<ExpandedState>({});

    const toggleExpand = (id: number) => {
        setExpandedItems(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    const formatDate = (timestamp: string) => {
        const date = new Date(timestamp);
        const twentyFourHoursAgo = subHours(new Date(), 24);
        if (date >= twentyFourHoursAgo) {
            return formatDistanceToNow(date, { addSuffix: true });
        }
        return format(date, 'MMM dd, yyyy');
    };

    const truncateText = (text: string | null, maxLength: number): string => {
        if (!text) return '';
        return text.length > maxLength 
            ? `${text.substring(0, maxLength)}...` 
            : text;
    };

    const renderParagraphs = (text: string) => {
        return text.split('\n\n').map((paragraph, index) => (
            <p key={index} className="mb-2">
                {paragraph}
            </p>
        ));
    };

    const getScoreColor = (value: number) => {
        if (value >= 7) return 'bg-green-200/50 dark:bg-green-200/50 text-myColor-800 dark:text-zinc-300';
        if (value >= 4) return 'bg-yellow-200/50 dark:bg-yellow-200/50 text-myColor-800 dark:text-zinc-300';
        return 'bg-red-200/50 dark:bg-red-200/50 text-myColor-800 dark:text-zinc-300';
    };

    return (
        <div className="space-y-4">
            {/* <NewsFilters filters={filters} onFilterChange={onFilterChange} /> */}
            <div className="overflow-x-auto">
                <div className="min-w-full space-y-4">
                    {news.map((item) => {
                        // console.log('News item:', item);
                        return (
                            <motion.div
                                key={item.id}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                className="px-6 py-3 dark:bg-zinc-800/50 bg-white/50 rounded-lg shadow
                                         hover:bg-myColor-100/50 dark:hover:bg-zinc-800/70 
                                         transition-colors duration-300"
                            >
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                    {/* Column 1: Title, Date, Publisher, Tags, Scores */}
                                    <div>
                                        <div className="flex items-center">
                                            <div className="text-sm text-myColor-700 dark:text-zinc-400 mr-4">
                                                {formatDate(item.published_at)}
                                            </div>
                                            <div className="flex flex-wrap gap-2">
                                                {item.tags?.map((tag, index) => (
                                                    <span
                                                        key={index}
                                                        className="px-2 py-1 text-xs rounded-full
                                                                 bg-myColor-200/70 dark:bg-zinc-700/70
                                                                 text-myColor-800 dark:text-zinc-300"
                                                    >
                                                        {tag.name}
                                                    </span>
                                                ))}
                                                {Boolean(item.ad) && (
                                                    <span
                                                        className="px-2 py-1 text-xs rounded-full
                                                                 bg-orange-200/70 dark:bg-orange-700/70
                                                                 text-orange-800 dark:text-orange-300"
                                                    >
                                                        Potentially Ad
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <motion.a 
                                            href={item.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            whileHover={{ x: 1 }}
                                        > 
                                            <h3 className="font-semibold text-xl text-myColor-900 dark:text-zinc-100 mt-2">
                                                {item.title}
                                            </h3>
                                        </motion.a>
                                        
                                        {/* Updated source, freshness, and sentiment row */}
                                        <div className="flex items-center gap-4 mt-2">
                                            <motion.a
                                                href={item.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center gap-2 text-sm font-light
                                                         text-myColor-800 dark:text-zinc-300
                                                         hover:text-myColor-900 dark:hover:text-zinc-100
                                                         transition-colors duration-300"
                                                whileHover={{ x: 1 }}
                                            >
                                                Source: {item.publisher?.name || 'Unknown Source'}
                                                <ArrowUpRight size={16} />
                                            </motion.a>
                                            
                                            {item.freshness_score !== null && (
                                                <div className="flex items-center gap-1">
                                                    <span className="font-light text-sm text-myColor-800 dark:text-zinc-300">Freshness:</span>
                                                    <span className={`px-2 py-[0.15rem] text-xs rounded-full font-semibold ${getScoreColor(item.freshness_score)}`}>
                                                        {item.freshness_score}
                                                    </span>
                                                </div>
                                            )}
                                            
                                            {item.sentiment_score !== null && (
                                                <div className="flex items-center gap-1">
                                                    <span className="font-light text-sm text-myColor-800 dark:text-zinc-300">Sentiment:</span>
                                                    <span className={`px-2 py-[0.15rem] text-xs rounded-full font-semibold ${getScoreColor(item.sentiment_score)}`}>
                                                        {item.sentiment_score}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* Column 2: Overview */}
                                    <div>
                                        <div className="text-myColor-800 dark:text-zinc-300">
                                            {expandedItems[item.id] 
                                                ? renderParagraphs(item.overview || '') 
                                                : truncateText(item.overview, 350)}
                                        </div>
                                        {item.overview && item.overview.trim() !== '' && (
                                            <button
                                                onClick={() => toggleExpand(item.id)}
                                                className="text-myColor-600 dark:text-zinc-400 hover:text-myColor-900 dark:hover:text-zinc-100 transition-colors duration-300 flex items-center gap-1"
                                            >
                                                {expandedItems[item.id] ? (
                                                    <>Show Less <ChevronUp size={16} /></>
                                                ) : (
                                                    <>Show More <ChevronDown size={16} /></>
                                                )}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </motion.div>
                        );
                    })}
                </div>
            </div>

            {hasMore && (
                <div className="mt-6 text-center">
                    <motion.button
                        onClick={onLoadMore}
                        disabled={loading}
                        className="px-6 py-3 bg-myColor-800 dark:bg-zinc-800
                                 text-white dark:text-zinc-100
                                 rounded-lg hover:bg-myColor-900 dark:hover:bg-zinc-700
                                 transition-colors duration-300 disabled:opacity-50"
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                    >
                        {loading ? 'Loading...' : 'Load More'}
                    </motion.button>
                </div>
            )}
        </div>
    );
};

export default NewsTable;