import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Category } from '../types/Category';
import { Tag } from '../types/Tag';

const CategoryManagement: React.FC = () => {
    const { api } = useAuth();
    const [categories, setCategories] = useState<Category[]>([]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [loading, setLoading] = useState(true);
    const [editingCategory, setEditingCategory] = useState<Category | null>(null);

    const [formData, setFormData] = useState({
        name: '',
        tagId: '',
        slug: '',
        description: '',
        metaTitle: '',
        metaDescription: '',
        isActive: true,
        order: 0
    });

    useEffect(() => {
        fetchCategories();
        fetchTags();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await api.get('/api/categories', {
                headers: {
                    'x-admin-request': 'true'
                }
            });
            setCategories(response.data);
        } catch (error) {
            console.error('Failed to fetch categories:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchTags = async () => {
        try {
            const response = await api.get('/api/tags');
            setTags(response.data);
        } catch (error) {
            console.error('Failed to fetch tags:', error);
        }
    };

    const handleDelete = async (id: number) => {
        if (window.confirm('Are you sure you want to delete this category?')) {
            try {
                await api.delete(`/api/categories/${id}`);
                fetchCategories(); // Refresh the list
            } catch (error) {
                console.error('Failed to delete category:', error);
            }
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const submitData = {
                ...formData,
                isActive: formData.isActive === undefined ? true : formData.isActive,
                order: formData.order || 0,
                tagId: parseInt(formData.tagId)
            };

            if (editingCategory) {
                await api.put(`/api/categories/${editingCategory.id}`, submitData);
            } else {
                await api.post('/api/categories', submitData);
            }
            fetchCategories();
            resetForm();
        } catch (error) {
            console.error('Failed to save category:', error);
        }
    };

    const handleEdit = (category: Category) => {
        setEditingCategory(category);
        setFormData({
            name: category.name,
            tagId: category.tagId ? category.tagId.toString() : '',
            slug: category.slug,
            description: category.description || '',
            metaTitle: category.metaTitle || '',
            metaDescription: category.metaDescription || '',
            isActive: category.isActive === undefined ? true : category.isActive,
            order: category.order || 0
        });
    };

    const resetForm = () => {
        setEditingCategory(null);
        setFormData({
            name: '',
            tagId: '',
            slug: '',
            description: '',
            metaTitle: '',
            metaDescription: '',
            isActive: true,
            order: 0
        });
    };

    if (loading) return <div>Loading...</div>;

    return (
        <div className="space-y-6">
            <div className="bg-white shadow rounded-lg p-6">
                <h2 className="text-xl font-semibold mb-4 text-gray-900 ">
                    {editingCategory ? 'Edit Category' : 'Create Category'}
                </h2>
                
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium mb-1">Name</label>
                            <input
                                type="text"
                                value={formData.name}
                                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                                className="w-full rounded-md border p-2"
                                required
                            />
                        </div>
                        
                        <div>
                            <label className="block text-sm font-medium mb-1">Tag</label>
                            <select
                                value={formData.tagId}
                                onChange={(e) => setFormData(prev => ({ ...prev, tagId: e.target.value }))}
                                className="w-full rounded-md border p-2"
                                required
                            >
                                <option value="">Select a tag</option>
                                {tags.map(tag => (
                                    <option key={tag.id} value={tag.id}>
                                        {tag.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Slug</label>
                            <input
                                type="text"
                                value={formData.slug}
                                onChange={(e) => setFormData(prev => ({ ...prev, slug: e.target.value }))}
                                className="w-full rounded-md border p-2"
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Order</label>
                            <input
                                type="number"
                                value={formData.order}
                                onChange={(e) => setFormData(prev => ({ ...prev, order: parseInt(e.target.value) }))}
                                className="w-full rounded-md border p-2"
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium mb-1">Description</label>
                        <textarea
                            value={formData.description}
                            onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                            className="w-full rounded-md border p-2"
                            rows={3}
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium mb-1">Meta Title</label>
                        <input
                            type="text"
                            value={formData.metaTitle}
                            onChange={(e) => setFormData(prev => ({ ...prev, metaTitle: e.target.value }))}
                            className="w-full rounded-md border p-2"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium mb-1">Meta Description</label>
                        <textarea
                            value={formData.metaDescription}
                            onChange={(e) => setFormData(prev => ({ ...prev, metaDescription: e.target.value }))}
                            className="w-full rounded-md border p-2"
                            rows={3}
                        />
                    </div>

                    <div className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            id="isActive"
                            checked={formData.isActive}
                            onChange={(e) => setFormData(prev => ({
                                ...prev,
                                isActive: e.target.checked
                            }))}
                            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                        <label htmlFor="isActive" className="text-gray-700 ">
                            Active
                        </label>
                    </div>

                    <div className="flex gap-2">
                        <button
                            type="submit"
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        >
                            {editingCategory ? 'Update' : 'Create'}
                        </button>
                        {editingCategory && (
                            <button
                                type="button"
                                onClick={resetForm}
                                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                            >
                                Cancel
                            </button>
                        )}
                    </div>
                </form>
            </div>

            <div className="bg-white  shadow rounded-lg p-6">
                <h2 className="text-xl font-semibold mb-4 text-gray-900">Categories</h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200 ">
                        <thead className="bg-gray-50 ">
                            <tr>
                                <th className="px-4 py-2">Name</th>
                                <th className="px-4 py-2">Slug</th>
                                <th className="px-4 py-2">Tag</th>
                                <th className="px-4 py-2">Status</th>
                                <th className="px-4 py-2">Order</th>
                                <th className="px-4 py-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white 0 divide-y divide-gray-200">
                            {categories.map(category => (
                                <tr key={category.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-gray-900 ">
                                        {category.name}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-gray-900 ">
                                        {category.slug}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-gray-900 ">
                                        {tags.find(t => t.id === category.tagId)?.name}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-gray-900 ">
                                        {category.isActive ? 'Active' : 'Inactive'}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-gray-900 ">
                                        {category.order}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            onClick={() => handleEdit(category)}
                                            className="text-blue-600 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300 mr-4"
                                        >
                                            Edit
                                        </button>
                                        <button
                                            onClick={() => handleDelete(category.id)}
                                            className="text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-300"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CategoryManagement; 