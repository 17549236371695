import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-hot-toast';

interface Page {
    id: number;
    path: string;
    template: string;
    meta_title: string | null;
    meta_description: string | null;
    og_image: string | null;
}

const TEMPLATES = ['Homepage', 'Standard', 'Contact'];

const PagesManagement: React.FC = () => {
    const { api } = useAuth();
    const [pages, setPages] = useState<Page[]>([]);
    const [selectedPage, setSelectedPage] = useState<Page | null>(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [newPage, setNewPage] = useState<Partial<Page>>({
        path: '',
        template: 'Standard',
        meta_title: '',
        meta_description: '',
        og_image: ''
    });

    useEffect(() => {
        fetchPages();
    }, []);

    const fetchPages = async () => {
        try {
            const response = await api.get('/api/pages');
            setPages(response.data);
        } catch (error) {
            toast.error('Failed to fetch pages');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!selectedPage) return;

        setSaving(true);
        try {
            await api.put(`/api/pages/${selectedPage.path}`, selectedPage);
            toast.success('Page updated successfully');
            fetchPages();
        } catch (error) {
            toast.error('Failed to update page');
        } finally {
            setSaving(false);
        }
    };

    const handleCreate = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await api.post('/api/pages', newPage);
            toast.success('Page created successfully');
            setIsCreating(false);
            setNewPage({
                path: '',
                template: 'Standard',
                meta_title: '',
                meta_description: '',
                og_image: ''
            });
            fetchPages();
        } catch (error) {
            toast.error('Failed to create page');
        }
    };

    const handleDelete = async (id: number) => {
        if (!window.confirm('Are you sure you want to delete this page?')) return;
        
        try {
            await api.delete(`/api/pages/${id}`);
            toast.success('Page deleted successfully');
            setSelectedPage(null);
            fetchPages();
        } catch (error) {
            toast.error('Failed to delete page');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold">Pages Management</h1>
                <button
                    onClick={() => setIsCreating(true)}
                    className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                >
                    Create New Page
                </button>
            </div>

            {/* Pages List */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                {/* Sidebar with pages list */}
                <div className="bg-white dark:bg-zinc-800 rounded-lg shadow p-4">
                    <h2 className="text-lg font-semibold mb-4">Pages</h2>
                    <div className="space-y-2">
                        {pages.map(page => (
                            <div
                                key={page.id}
                                className={`p-2 rounded cursor-pointer ${
                                    selectedPage?.id === page.id
                                        ? 'bg-blue-100 dark:bg-blue-900'
                                        : 'hover:bg-gray-100 dark:hover:bg-zinc-700'
                                }`}
                                onClick={() => setSelectedPage(page)}
                            >
                                {page.path}
                            </div>
                        ))}
                    </div>
                </div>

                {/* Edit Form */}
                <div className="md:col-span-3">
                    {selectedPage ? (
                        <div className="bg-white dark:bg-zinc-800 rounded-lg shadow p-6">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-xl font-semibold">Edit Page: {selectedPage.path}</h2>
                                <button
                                    onClick={() => handleDelete(selectedPage.id)}
                                    className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                                >
                                    Delete
                                </button>
                            </div>
                            
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium mb-1">
                                        Template
                                    </label>
                                    <select
                                        value={selectedPage.template}
                                        onChange={(e) => setSelectedPage({ ...selectedPage, template: e.target.value })}
                                        className="w-full p-2 border rounded dark:bg-zinc-700 dark:border-zinc-600"
                                    >
                                        {TEMPLATES.map(template => (
                                            <option key={template} value={template}>
                                                {template}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium mb-1">
                                        Meta Title
                                    </label>
                                    <input
                                        type="text"
                                        value={selectedPage.meta_title || ''}
                                        onChange={(e) => setSelectedPage({ ...selectedPage, meta_title: e.target.value })}
                                        className="w-full p-2 border rounded dark:bg-zinc-700 dark:border-zinc-600"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium mb-1">
                                        Meta Description
                                    </label>
                                    <textarea
                                        value={selectedPage.meta_description || ''}
                                        onChange={(e) => setSelectedPage({ ...selectedPage, meta_description: e.target.value })}
                                        className="w-full p-2 border rounded dark:bg-zinc-700 dark:border-zinc-600"
                                        rows={4}
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium mb-1">
                                        OG Image URL
                                    </label>
                                    <input
                                        type="text"
                                        value={selectedPage.og_image || ''}
                                        onChange={(e) => setSelectedPage({ ...selectedPage, og_image: e.target.value })}
                                        className="w-full p-2 border rounded dark:bg-zinc-700 dark:border-zinc-600"
                                    />
                                </div>

                                <div className="flex justify-end">
                                    <button
                                        type="submit"
                                        disabled={saving}
                                        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:opacity-50"
                                    >
                                        {saving ? 'Saving...' : 'Save Changes'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <div className="bg-white dark:bg-zinc-800 rounded-lg shadow p-6">
                            <p className="text-gray-500">Select a page to edit</p>
                        </div>
                    )}
                </div>
            </div>

            {/* Create New Page Modal */}
            {isCreating && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white dark:bg-zinc-800 rounded-lg shadow-lg p-6 max-w-lg w-full mx-4">
                        <h2 className="text-xl font-semibold mb-4">Create New Page</h2>
                        <form onSubmit={handleCreate} className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium mb-1">
                                    Path
                                </label>
                                <input
                                    type="text"
                                    value={newPage.path}
                                    onChange={(e) => setNewPage({ ...newPage, path: e.target.value })}
                                    className="w-full p-2 border rounded dark:bg-zinc-700 dark:border-zinc-600"
                                    placeholder="/about"
                                    required
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium mb-1">
                                    Template
                                </label>
                                <select
                                    value={newPage.template}
                                    onChange={(e) => setNewPage({ ...newPage, template: e.target.value })}
                                    className="w-full p-2 border rounded dark:bg-zinc-700 dark:border-zinc-600"
                                >
                                    {TEMPLATES.map(template => (
                                        <option key={template} value={template}>
                                            {template}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <label className="block text-sm font-medium mb-1">
                                    Meta Title
                                </label>
                                <input
                                    type="text"
                                    value={newPage.meta_title || ''}
                                    onChange={(e) => setNewPage({ ...newPage, meta_title: e.target.value })}
                                    className="w-full p-2 border rounded dark:bg-zinc-700 dark:border-zinc-600"
                                    required
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium mb-1">
                                    Meta Description
                                </label>
                                <textarea
                                    value={newPage.meta_description || ''}
                                    onChange={(e) => setNewPage({ ...newPage, meta_description: e.target.value })}
                                    className="w-full p-2 border rounded dark:bg-zinc-700 dark:border-zinc-600"
                                    rows={4}
                                />
                            </div>

                            <div className="flex justify-end space-x-2">
                                <button
                                    type="button"
                                    onClick={() => setIsCreating(false)}
                                    className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                                >
                                    Create
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PagesManagement; 