import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

interface APILog {
    id: number;
    action: string;
    entity_type: string;
    entity_id: number;
    status: string;
    details: string;
    created_at: string;
    publisher_name?: string;
}

interface Article {
    id: number;
    title: string;
    content: string;
    url: string;
    published_at: string;
    publisher_id: number;
}

interface ArticleAnalytics {
    article: Article;
    contentLength: number;
    tokensUsed: number;
    promptTokens: number;
    completionTokens: number;
    lastAnalyzed: string;
    status: string;
    cost: number;
    model: string;
}

interface GroupedData {
    [key: string]: {
        count: number;
        totalTokens: number;
        successCount: number;
        failureCount: number;
        averageContentLength: number;
        totalCost: number;
        modelCounts: { [model: string]: number };
        publisher_id?: number;
    };
}

// Add pricing constants
const MODEL_PRICING = {
    'gpt-4': {
        promptTokens: 0.0025, // $2.50 per 1M tokens
        completionTokens: 0.01  // $10.00 per 1M tokens
    },
    'deepseek-chat': {
        promptTokens: 0.00007, // $0.07 per 1M tokens
        completionTokens: 0.0011 // $1.1 per 1M tokens
    }
};

const APIUsage: React.FC = () => {
    const { api } = useAuth();
    const [logs, setLogs] = useState<APILog[]>([]);
    const [loading, setLoading] = useState(true);
    const [groupBy, setGroupBy] = useState<'day' | 'publisher'>('day');
    const [dateRange, setDateRange] = useState<'today' | 'week' | 'month'>('week');
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const [articles, setArticles] = useState<{ [key: string]: ArticleAnalytics[] }>({});

    const fetchLogs = async () => {
        try {
            const response = await api.get('/api/logs/ai-usage', {
                params: { dateRange }
            });
            setLogs(response.data);
        } catch (error) {
            console.error('Failed to fetch API usage logs:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLogs();
    }, [dateRange]);

    const fetchArticleAnalytics = async (publisherId: number): Promise<ArticleAnalytics[]> => {
        try {
            // Get articles
            const articlesResponse = await api.get(`/api/articles/publisher/${publisherId}`);
            const articles = articlesResponse.data;

            // Get analytics for these articles
            const logsResponse = await api.get('/api/logs/ai-usage/articles', {
                params: { 
                    publisher_id: publisherId,
                    article_ids: articles.map((a: Article) => a.id).join(',')
                }
            });
            const logsData = logsResponse.data;

            // Combine article data with analytics
            return articles.map((article: Article) => {
                const analytics = logsData.find((log: any) => log.entity_id === article.id);
                
                // Calculate costs based on actual token usage and model
                const promptTokens = analytics?.prompt_tokens || 0;
                const completionTokens = analytics?.completion_tokens || 0;
                const totalTokens = analytics?.total_tokens || 0;
                const model = analytics?.model || 'gpt-4';
                
                const pricing = MODEL_PRICING[model as keyof typeof MODEL_PRICING] || MODEL_PRICING['gpt-4'];
                const promptCost = (promptTokens * pricing.promptTokens) / 1000;
                const completionCost = (completionTokens * pricing.completionTokens) / 1000;
                const totalCost = promptCost + completionCost;

                return {
                    article,
                    contentLength: article.content?.length || 0,
                    tokensUsed: totalTokens,
                    promptTokens: promptTokens,
                    completionTokens: completionTokens,
                    lastAnalyzed: analytics?.analyzed_at || 'Never',
                    status: analytics?.status || 'Not analyzed',
                    cost: totalCost,
                    model: model
                };
            });
        } catch (error) {
            console.error('Failed to fetch article analytics:', error);
            return [];
        }
    };

    const handleRowClick = async (key: string, publisherId?: number) => {
        if (groupBy !== 'publisher' || !publisherId) return;
        
        if (expandedRow === key) {
            setExpandedRow(null);
            return;
        }

        setExpandedRow(key);
        if (!articles[key]) {
            const articleAnalytics = await fetchArticleAnalytics(publisherId);
            setArticles(prev => ({ ...prev, [key]: articleAnalytics }));
        }
    };

    const groupData = (): GroupedData => {
        return logs.reduce((acc: GroupedData, log) => {
            try {
                const details = JSON.parse(log.details);
                const requestDetails = log.action === 'ai_response' ? 
                    details.request : details;
                const responseDetails = log.action === 'ai_response' ? 
                    details.response : null;

                const key = groupBy === 'day' 
                    ? new Date(requestDetails.requestTimestamp).toLocaleDateString()
                    : log.publisher_name || 'Unknown Publisher';

                if (!acc[key]) {
                    acc[key] = {
                        count: 0,
                        totalTokens: 0,
                        successCount: 0,
                        failureCount: 0,
                        averageContentLength: 0,
                        totalCost: 0,
                        modelCounts: {},
                        publisher_id: requestDetails.publisher_id
                    };
                }

                // Track model usage
                const model = requestDetails.model || 'unknown';
                acc[key].modelCounts[model] = (acc[key].modelCounts[model] || 0) + 1;

                const contentLength = requestDetails.contentLength || 0;
                acc[key].count++;
                acc[key].totalTokens += responseDetails?.totalTokens || requestDetails.tokenEstimate || 0;
                acc[key].averageContentLength = 
                    ((acc[key].averageContentLength * (acc[key].count - 1)) + contentLength) / acc[key].count || 0;
                
                // Calculate cost based on model-specific pricing
                if (responseDetails?.totalTokens) {
                    const pricing = MODEL_PRICING[model as keyof typeof MODEL_PRICING] || MODEL_PRICING['gpt-4'];
                    const promptCost = (responseDetails.promptTokens || 0) * pricing.promptTokens / 1000;
                    const completionCost = (responseDetails.completionTokens || 0) * pricing.completionTokens / 1000;
                    acc[key].totalCost += promptCost + completionCost;
                }

                if (log.status === 'success') acc[key].successCount++;
                if (log.status === 'failed') acc[key].failureCount++;

                return acc;
            } catch (e) {
                console.error('Error parsing log details:', e);
                return acc;
            }
        }, {});
    };

    const groupedData = groupData();

    if (loading) return <div>Loading...</div>;

    return (
        <div className="p-6">
            <div className="mb-6 flex justify-between items-center">
                <h1 className="text-2xl font-bold">API Usage Analytics</h1>
                <div className="space-x-4">
                    <select 
                        value={groupBy}
                        onChange={(e) => setGroupBy(e.target.value as 'day' | 'publisher')}
                        className="rounded border p-2"
                    >
                        <option value="day">Group by Day</option>
                        <option value="publisher">Group by Publisher</option>
                    </select>
                    <select 
                        value={dateRange}
                        onChange={(e) => setDateRange(e.target.value as 'today' | 'week' | 'month')}
                        className="rounded border p-2"
                    >
                        <option value="today">Today</option>
                        <option value="week">Last 7 Days</option>
                        <option value="month">Last 30 Days</option>
                    </select>
                </div>
            </div>

            <div className="bg-white shadow-md rounded">
                <table className="min-w-full">
                    <thead>
                        <tr className="bg-gray-50">
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                {groupBy === 'day' ? 'Date' : 'Publisher'}
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                Models Used
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                Total Requests
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                Est. Tokens
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                Success Rate
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                Avg Content Length
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                                Est. Cost (USD)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(groupedData).map(([key, data]) => (
                            <React.Fragment key={key}>
                                <tr 
                                    className={`border-b hover:bg-gray-50 cursor-pointer ${
                                        expandedRow === key ? 'bg-gray-50' : ''
                                    }`}
                                    onClick={() => handleRowClick(key, data.publisher_id)}
                                >
                                    <td className="px-6 py-4 whitespace-nowrap">{key}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {Object.entries(data.modelCounts || {}).map(([model, count]) => (
                                            <div key={model} className="text-sm">
                                                {model}: {count} requests
                                            </div>
                                        ))}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">{data.count}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {data.totalTokens.toLocaleString()}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {((data.successCount / data.count) * 100 || 0).toFixed(1)}%
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {Math.round(data.averageContentLength || 0).toLocaleString()}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        ${data.totalCost.toFixed(2)}
                                    </td>
                                </tr>
                                {expandedRow === key && groupBy === 'publisher' && (
                                    <tr>
                                        <td colSpan={6} className="px-6 py-4 bg-gray-50">
                                            <div className="space-y-2">
                                                {articles[key]?.map((analytics: ArticleAnalytics) => (
                                                    <div key={analytics.article.id} className="p-4 bg-white rounded shadow">
                                                        <div className="flex justify-between items-start">
                                                            <div className="flex-1">
                                                                <a 
                                                                    href={analytics.article.url} 
                                                                    target="_blank" 
                                                                    rel="noopener noreferrer"
                                                                    className="text-blue-600 hover:text-blue-800 font-medium"
                                                                >
                                                                    {analytics.article.title}
                                                                </a>
                                                                <div className="mt-2 grid grid-cols-2 gap-4 text-sm text-gray-600">
                                                                    <div>
                                                                        <span className="font-semibold">Content Length:</span>{' '}
                                                                        {analytics.contentLength.toLocaleString()} chars
                                                                    </div>
                                                                    <div>
                                                                        <span className="font-semibold">Total Tokens:</span>{' '}
                                                                        {analytics.tokensUsed.toLocaleString()}
                                                                    </div>
                                                                    <div>
                                                                        <span className="font-semibold">Prompt Tokens:</span>{' '}
                                                                        {analytics.promptTokens.toLocaleString()}
                                                                    </div>
                                                                    <div>
                                                                        <span className="font-semibold">Completion Tokens:</span>{' '}
                                                                        {analytics.completionTokens.toLocaleString()}
                                                                    </div>
                                                                    <div>
                                                                        <span className="font-semibold">Last Analyzed:</span>{' '}
                                                                        {analytics.lastAnalyzed === 'Never' ? 'Never' : 
                                                                         new Date(analytics.lastAnalyzed).toLocaleString()}
                                                                    </div>
                                                                    <div>
                                                                        <span className="font-semibold">Cost:</span>{' '}
                                                                        ${analytics.cost.toFixed(4)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span className={`ml-4 px-2 py-1 rounded text-sm ${
                                                                analytics.status === 'success' 
                                                                    ? 'bg-green-100 text-green-800' 
                                                                    : 'bg-gray-100 text-gray-800'
                                                            }`}>
                                                                {analytics.status}
                                                            </span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default APIUsage; 