import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Filter } from 'lucide-react';

interface FilterOption {
    label: string;
    value: string;
}

interface PresetFilter {
    label: string;
    description: string;
    config: {
        freshness_score?: string;
        sentiment_score?: string;
        no_ads?: boolean;
        category?: string;
    };
}

const PRESET_FILTERS: PresetFilter[] = [
    {
        label: "The Best",
        description: "High-quality content without ads",
        config: { 
            freshness_score: "7-10",
            no_ads: true,
            sentiment_score: '',
            category: ''
        }
    },
    {
        label: "Bearish",
        description: "Negative market sentiment",
        config: { 
            sentiment_score: "0-3",
            freshness_score: '',
            no_ads: false,
            category: ''
        }
    },
    {
        label: "Bullish",
        description: "Positive market sentiment",
        config: { 
            sentiment_score: "7-10",
            freshness_score: '',
            no_ads: false,
            category: ''
        }
    }
];

interface NewsFiltersPopupProps {
    isOpen: boolean;
    onClose: () => void;
    filters: any;
    onFilterChange: (key: string, value: any) => void;
    categories: FilterOption[];
}

const NewsFiltersPopup: React.FC<NewsFiltersPopupProps> = ({
    isOpen,
    onClose,
    filters,
    onFilterChange,
    categories
}) => {
    const scoreRanges = [
        { label: 'All', value: '' },
        { label: 'Low (0-3)', value: '0-3' },
        { label: 'Medium (4-6)', value: '4-6' },
        { label: 'High (7-10)', value: '7-10' }
    ];

    const applyPresetFilter = (preset: PresetFilter) => {
        const resetFilters = {
            category: '',
            freshness_score: '',
            sentiment_score: '',
            no_ads: false
        };

        Object.entries(resetFilters).forEach(([key, value]) => {
            onFilterChange(key, value);
        });

        setTimeout(() => {
            Object.entries(preset.config).forEach(([key, value]) => {
                if (value !== '') {
                    onFilterChange(key, value);
                }
            });
        }, 0);
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <div className="fixed inset-0 z-50 overflow-y-auto">
                    {/* Backdrop */}
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 bg-black/20 dark:bg-black/40"
                        onClick={onClose}
                    />

                    {/* Center container */}
                    <div className="min-h-screen px-4 text-center">
                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>

                        {/* Popup */}
                        <motion.div
                            initial={{ opacity: 0, scale: 0.95 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.95 }}
                            className="inline-block w-full max-w-md p-6 my-8 
                                     align-middle rounded-xl
                                     bg-zinc-200 dark:bg-zinc-900
                                     border border-myColor-400/50 dark:border-zinc-700/50
                                     shadow-xl
                                     text-left overflow-hidden
                                     transform transition-all
                                     relative z-[51]"
                        >
                            <div className="flex justify-between items-center mb-6">
                                <h3 className="text-lg font-semibold text-myColor-800 dark:text-zinc-200">
                                    Filters
                                </h3>
                                <button
                                    onClick={onClose}
                                    className="p-1 rounded-lg hover:bg-myColor-200/50 dark:hover:bg-zinc-800/50
                                             text-myColor-600 dark:text-zinc-400
                                             transition-colors duration-200"
                                >
                                    <X className="w-5 h-5" />
                                </button>
                            </div>

                            {/* Preset Filters */}
                            <div className="mb-6">
                                <h4 className="text-sm font-medium text-myColor-700 dark:text-zinc-300 mb-3">
                                    Preset Filters
                                </h4>
                                <div className="grid grid-cols-1 gap-2">
                                    {PRESET_FILTERS.map((preset) => (
                                        <button
                                            key={preset.label}
                                            onClick={() => {
                                                applyPresetFilter(preset);
                                                onClose();
                                            }}
                                            className="flex flex-col items-start w-full p-3 rounded-lg
                                                     bg-myColor-100/50 dark:bg-zinc-800/50
                                                     hover:bg-myColor-200/50 dark:hover:bg-zinc-700/50
                                                     transition-all duration-300 ease-in-out"
                                        >
                                            <span className="font-medium text-myColor-800 dark:text-zinc-200">
                                                {preset.label}
                                            </span>
                                            <span className="text-sm text-myColor-600 dark:text-zinc-400">
                                                {preset.description}
                                            </span>
                                        </button>
                                    ))}
                                </div>
                            </div>

                            {/* Custom Filters */}
                            <div className="space-y-4">
                                <div>
                                    <label className="text-sm font-medium text-myColor-700 dark:text-zinc-300">
                                        Category
                                    </label>
                                    <select
                                        value={filters.category || ''}
                                        onChange={(e) => onFilterChange('category', e.target.value)}
                                        className="mt-1 w-full p-2 rounded-lg
                                                 bg-myColor-100/50 dark:bg-zinc-800/50
                                                 border border-myColor-400/50 dark:border-zinc-700/50
                                                 text-myColor-800 dark:text-zinc-300"
                                    >
                                        <option value="">All Categories</option>
                                        {categories.map((category) => (
                                            <option key={category.value} value={category.value}>
                                                {category.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div>
                                    <label className="text-sm font-medium text-myColor-700 dark:text-zinc-300">
                                        Freshness Score
                                    </label>
                                    <select
                                        value={filters.freshness_score || ''}
                                        onChange={(e) => onFilterChange('freshness_score', e.target.value)}
                                        className="mt-1 w-full p-2 rounded-lg
                                                 bg-myColor-100/50 dark:bg-zinc-800/50
                                                 border border-myColor-400/50 dark:border-zinc-700/50
                                                 text-myColor-800 dark:text-zinc-300"
                                    >
                                        {scoreRanges.map((range) => (
                                            <option key={range.value} value={range.value}>
                                                {range.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div>
                                    <label className="text-sm font-medium text-myColor-700 dark:text-zinc-300">
                                        Sentiment Score
                                    </label>
                                    <select
                                        value={filters.sentiment_score || ''}
                                        onChange={(e) => onFilterChange('sentiment_score', e.target.value)}
                                        className="mt-1 w-full p-2 rounded-lg
                                                 bg-myColor-100/50 dark:bg-zinc-800/50
                                                 border border-myColor-400/50 dark:border-zinc-700/50
                                                 text-myColor-800 dark:text-zinc-300"
                                    >
                                        {scoreRanges.map((range) => (
                                            <option key={range.value} value={range.value}>
                                                {range.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id="no_ads"
                                        checked={filters.no_ads || false}
                                        onChange={(e) => onFilterChange('no_ads', e.target.checked)}
                                        className="rounded border-myColor-400 dark:border-zinc-700
                                                 text-myColor-600 dark:text-zinc-400
                                                 focus:ring-myColor-500 dark:focus:ring-zinc-600"
                                    />
                                    <label
                                        htmlFor="no_ads"
                                        className="ml-2 text-sm font-medium text-myColor-700 dark:text-zinc-300"
                                    >
                                        No sponsored content
                                    </label>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            )}
        </AnimatePresence>
    );
};

export default NewsFiltersPopup; 