import React from 'react';
import { Search } from 'lucide-react';
import { motion } from 'framer-motion';

interface NewsSearchProps {
    value: string;
    onChange: (value: string) => void;
}

const NewsSearch: React.FC<NewsSearchProps> = ({ value, onChange }) => {
    return (
        <div className="relative flex-1">
            <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-myColor-600 dark:text-zinc-400" />
                <input
                    type="text"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder="Search news..."
                    className="w-full pl-10 pr-4 py-2 rounded-lg
                             bg-myColor-100/50 dark:bg-zinc-800/50
                             border border-myColor-400/50 dark:border-zinc-700/50
                             text-myColor-800 dark:text-zinc-300
                             placeholder-myColor-600 dark:placeholder-zinc-500
                             focus:ring-2 focus:ring-myColor-600/50 dark:focus:ring-zinc-600/50
                             focus:border-transparent
                             transition-all duration-300"
                />
            </div>
        </div>
    );
};

export default NewsSearch; 