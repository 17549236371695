import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../navigation/Navbar';

const PublicLayout: React.FC = () => {
    return (
        <>
            <div className="fixed inset-0 bg-bg-gradient-light dark:bg-bg-gradient-dark" />
            <div className="min-h-screen relative z-10 text-light-text dark:text-dark-text transition-colors duration-50">
                <Navbar />
                <main className="container mx-auto px-4 py-8">
                    <Outlet />
                </main>
            </div>
        </>
    );
};

export default PublicLayout; 