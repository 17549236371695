import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleTime } from '@visx/scale';
import type { NumberValue } from 'd3-scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { LinearGradient } from '@visx/gradient';
import { useTooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { useTheme } from '../../context/ThemeContext';
import { bisector } from 'd3-array';

interface SentimentTrend {
    date: string;
    avgSentimentScore: string;
}

interface CoinData {
    price_usd: number;
    price_change_24h_percent: number;
    name: string;
    volume_24h: number;
    market_cap: number;
    updated_at: Date;
}

interface PriceHistoryPoint {
    timestamp: string;
    price: number;
}

interface MarketDataItem {
    symbol: string;
    price: number;
    change: number;
    volume: string;
    market_cap: number;
}

interface TimeframeOption {
    label: string;
    value: string;
}

interface CoinOption {
    symbol: string;
    name: string;
    market_cap: number;
}

interface TopMover {
    symbol: string;
    name: string;
    price_usd: number;
    price_change_24h_percent: number;
    volume_24h: number;
    market_cap: number;
}

const TIMEFRAME_OPTIONS: TimeframeOption[] = [
    { label: '24h', value: '24h' },
    { label: '7d', value: '7d' },
    { label: '30d', value: '30d' },
    { label: 'All', value: 'all' }
];

const MarketOverview: React.FC = () => {
    const { api } = useAuth();
    const { theme } = useTheme();
    const isDarkMode = theme === 'dark';
    
    const [selectedCoin, setSelectedCoin] = useState<string>('BTC');
    const [selectedCoinName, setSelectedCoinName] = useState<string>('');
    const [availableCoins, setAvailableCoins] = useState<CoinOption[]>([]);
    const [coinData, setCoinData] = useState<CoinData | null>(null);
    const [priceHistory, setPriceHistory] = useState<PriceHistoryPoint[]>([]);
    const [trends, setTrends] = useState<SentimentTrend[]>([]);
    const [selectedTimeframe, setSelectedTimeframe] = useState<string>('24h');
    const [timeframePriceChange, setTimeframePriceChange] = useState<number>(0);
    const [topMovers, setTopMovers] = useState<{ gainers: TopMover[], losers: TopMover[] }>({
        gainers: [],
        losers: []
    });

    // Define colors and styles
    const axisColor = isDarkMode ? '#666' : '#999';
    const chartBackground = isDarkMode ? '#1a202c' : '#f7fafc';
    const accentColorDark = isDarkMode ? '#4FD1C5' : '#319795';

    // Sample market data (replace with real data later)
    const [marketData, setMarketData] = useState<MarketDataItem[]>([
        {
            symbol: 'BTC',
            price: 65000,
            change: 2.5,
            volume: '32.5B',
            market_cap: 1000000000000
        },
        {
            symbol: 'ETH',
            price: 3500,
            change: -1.2,
            volume: '15.2B',
            market_cap: 1000000000000
        }
    ]);

    

    const selectClassName = `
        bg-transparent border rounded px-2 py-1 text-sm
        bg-myColor-100 dark:bg-zinc-800 
        text-myColor-800 dark:text-zinc-300
        border-myColor-400 dark:border-zinc-700
        hover:border-myColor-600 dark:hover:border-zinc-600
        focus:ring-2 focus:ring-myColor-600 dark:focus:ring-zinc-500
        focus:border-myColor-600 dark:focus:border-zinc-500
        transition-colors duration-300
    `;

    // Fetch coins with market cap data
    useEffect(() => {
        const fetchCoins = async () => {
            try {
                const response = await api.get('/api/crypto/coins');
                const coinsWithData = await Promise.all(
                    response.data.map(async (coin: any) => {
                        const dataResponse = await api.get(`/api/crypto/data/${coin.symbol}`);
                        return {
                            symbol: coin.symbol,
                            name: coin.name,
                            market_cap: dataResponse.data?.market_cap || 0
                        };
                    })
                );

                // Sort by market cap
                const sortedCoins = coinsWithData.sort((a, b) => b.market_cap - a.market_cap);
                setAvailableCoins(sortedCoins);
                
                if (sortedCoins.length > 0 && !selectedCoin) {
                    setSelectedCoin(sortedCoins[0].symbol);
                    setSelectedCoinName(sortedCoins[0].name);
                }
            } catch (error) {
                console.error('Failed to fetch coins:', error);
            }
        };

        fetchCoins();
    }, [api, selectedCoin]);

    // Update selected coin name when coin changes
    useEffect(() => {
        const coin = availableCoins.find(c => c.symbol === selectedCoin);
        if (coin) {
            setSelectedCoinName(coin.name);
        }
    }, [selectedCoin, availableCoins]);

    // Fetch coin data and update periodically
    useEffect(() => {
        const fetchCoinData = async () => {
            if (!selectedCoin) return;

            try {
                const [dataResponse, historyResponse] = await Promise.all([
                    api.get(`/api/crypto/data/${selectedCoin}`),
                    api.get(`/api/crypto/history/${selectedCoin}/${selectedTimeframe}`)
                ]);

                if (dataResponse.data) {
                    setCoinData(dataResponse.data);
                }
                if (historyResponse.data) {
                    setPriceHistory(historyResponse.data);
                }
            } catch (error) {
                console.error('Error fetching coin data:', error);
            }
        };

        if (selectedCoin) {
            fetchCoinData();
            const interval = setInterval(fetchCoinData, 300000);
            return () => clearInterval(interval);
        }
    }, [api, selectedCoin, selectedTimeframe]);

    // Tooltip setup
    const { 
        showTooltip, 
        hideTooltip, 
        tooltipData, 
        tooltipTop, 
        tooltipLeft 
    } = useTooltip<PriceHistoryPoint>();
    const tooltipStyles = {
        ...defaultStyles,
        backgroundColor: 'rgba(0,0,0,0.9)',
        color: 'white',
    };

    // Filter out zero values and format dates
    const filteredTrends = trends.filter((trend: SentimentTrend) => parseFloat(trend.avgSentimentScore) > 0);

    // Define dimensions and margins
    const width = 500;
    const height = 200;
    const margin = { top: 20, right: 20, bottom: 30, left: 50 };
    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    // Set up scales
    const xScale = scaleTime<number>({
        domain: priceHistory.length > 0 ? [
            Math.min(...priceHistory.map(d => new Date(d.timestamp).getTime())),
            Math.max(...priceHistory.map(d => new Date(d.timestamp).getTime()))
        ] : [new Date().getTime(), new Date().getTime()],
        range: [0, xMax],
        round: true
    });

    const yScale = scaleLinear<number>({
        domain: priceHistory.length > 0 ? [
            Math.min(...priceHistory.map(d => d.price)) * 0.99,
            Math.max(...priceHistory.map(d => d.price)) * 1.01
        ] : [0, 100],
        range: [yMax, 0],
        round: true
    });

    // Format time for x-axis
    const formatTime = (value: Date | NumberValue) => {
        if (value instanceof Date) {
            return value.toLocaleTimeString([], { 
                hour: '2-digit', 
                minute: '2-digit' 
            });
        }
        return new Date(value.valueOf()).toLocaleTimeString([], { 
            hour: '2-digit', 
            minute: '2-digit' 
        });
    };

    // Define gradient colors
    const gradientColors = {
        from: isDarkMode ? 'rgba(147, 51, 234, 0.3)' : 'rgba(168, 85, 247, 0.2)',
        to: isDarkMode ? 'rgba(147, 51, 234, 0.0)' : 'rgba(168, 85, 247, 0.0)',
        line: isDarkMode ? '#8656b5' : '#9333ea'
    };

    // Format date based on timeframe
    const formatDate = (date: Date | NumberValue) => {
        const d = date instanceof Date ? date : new Date(date.valueOf());
        
        switch(selectedTimeframe) {
            case '24h':
                return d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            case '7d':
            case '30d':
            case 'all':
                return d.toLocaleDateString([], { day: '2-digit', month: '2-digit' });
            default:
                return '';
        }
    };

    // Helper for finding nearest data point
    const bisectDate = bisector<PriceHistoryPoint, Date>(
        (d) => new Date(d.timestamp)
    ).left;

    // Handle mouse move over chart with fixed positioning
    const handleMouseMove = (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
        const { left, width } = event.currentTarget.getBoundingClientRect();
        const { clientX } = 'touches' in event ? event.touches[0] : event;
        const xRatio = (clientX - left) / width;
        const xPos = xRatio * xMax;
        const xDate = xScale.invert(xPos);
        const index = bisectDate(priceHistory, xDate, 1);
        
        if (index > 0 && index < priceHistory.length) {
            const d0 = priceHistory[index - 1];
            const d1 = priceHistory[index];
            const d = xDate.getTime() - new Date(d0.timestamp).getTime() > new Date(d1.timestamp).getTime() - xDate.getTime() ? d1 : d0;
            
            showTooltip({
                tooltipData: d,
                tooltipLeft: xScale(new Date(d.timestamp).getTime()) + margin.left,
                tooltipTop: yScale(d.price) + margin.top,
            });
        }
    };

    // Calculate price change based on timeframe
    useEffect(() => {
        if (priceHistory.length >= 2) {
            const oldestPrice = priceHistory[0].price;
            const latestPrice = priceHistory[priceHistory.length - 1].price;
            const change = ((latestPrice - oldestPrice) / oldestPrice) * 100;
            setTimeframePriceChange(change);
        }
    }, [priceHistory]);

    // Add fetch for top movers
    useEffect(() => {
        const fetchTopMovers = async () => {
            try {
                const response = await api.get('/api/crypto/top-movers');
                setTopMovers(response.data);
            } catch (error) {
                console.error('Failed to fetch top movers:', error);
            }
        };

        fetchTopMovers();
        const interval = setInterval(fetchTopMovers, 300000); // Update every 5 minutes
        return () => clearInterval(interval);
    }, [api]);

    // Helper function to format numbers
    const formatNumber = (num: number | string | null | undefined, decimals = 2) => {
        if (num === null || num === undefined) return '$0';
        
        // Convert to number if it's a string
        const numValue = typeof num === 'string' ? parseFloat(num) : num;
        
        // Check if conversion resulted in a valid number
        if (isNaN(numValue)) return '$0';
        
        if (numValue >= 1e9) return `$${(numValue / 1e9).toFixed(decimals)}B`;
        if (numValue >= 1e6) return `$${(numValue / 1e6).toFixed(decimals)}M`;
        return `$${numValue.toFixed(decimals)}`;
    };

    // Add helper function for formatting percentages
    const formatPercentage = (value: number | string | null | undefined) => {
        if (value === null || value === undefined) return '0.00';
        
        const numValue = typeof value === 'string' ? parseFloat(value) : value;
        if (isNaN(numValue)) return '0.00';
        
        return numValue >= 0 ? `+${numValue.toFixed(2)}` : numValue.toFixed(2);
    };

    return (
        <div className="grid grid-cols-1 lg:grid-cols-3 px-6 gap-6">
            {/* First column - price chart */}
            <div className="lg:col-span-1 dark:bg-zinc-800/50 bg-white/50  rounded-lg shadow p-6 relative overflow-hidden">
                {/* Dotted background with gradient */}
                <div className="absolute inset-0 bg-chart-gradient-light dark:bg-chart-gradient-dark">
                    <div className="absolute inset-0 dark:bg-dots-gradient-dark bg-dots-gradient-light bg-dots"
                        style={{ 
                            maskImage: 'linear-gradient(to right bottom, black -120%, transparent)',
                            WebkitMaskImage: 'linear-gradient(to right bottom, black -120%, transparent)'
                        }}>
                    </div>
                </div>

                {/* Content layer */}
                <div className="relative">
                    <div className="flex justify-between items-center mb-4">
                        <div className="flex items-center space-x-3">
                            {selectedCoin && (
                                <>
                                    <img 
                                        src={`/icon/black/${selectedCoin.toLowerCase()}.svg`}
                                        alt={`${selectedCoin} icon`}
                                        className="w-6 h-6 dark:invert"
                                        onError={(e) => {
                                            e.currentTarget.style.display = 'none';
                                        }}
                                    />
                                    <div className="flex items-center space-x-2">
                                        <span className="text-lg font-medium text-myColor-800 dark:text-zinc-300">
                                        {selectedCoinName}
                                        </span>
                                        <span className="text-sm text-myColor-600 dark:text-zinc-400">
                                        {selectedCoin}
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                        <select
                            value={selectedCoin}
                            onChange={(e) => setSelectedCoin(e.target.value)}
                            className={selectClassName}
                        >
                            {availableCoins.map((coin) => (
                                <option key={coin.symbol} value={coin.symbol}>
                                    {coin.symbol}
                                </option>
                            ))}
                        </select>
                    </div>
                    
                    {coinData ? (
                        <div className="space-y-4">
                            <div className="flex items-center space-x-2">
                                <span className="text-2xl font-semibold text-myColor-800 dark:text-zinc-300">
                                    ${Number(coinData.price_usd).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </span>
                                <span className={`${
                                    Number(coinData.price_change_24h_percent) >= 0
                                        ? 'text-green-500 dark:text-green-400' 
                                        : 'text-red-500 dark:text-red-400'
                                }`}>
                                    {selectedTimeframe === '24h' 
                                        ? (Number(coinData.price_change_24h_percent) >= 0 ? '+' : '') +
                                          Number(coinData.price_change_24h_percent).toFixed(2)
                                        : (timeframePriceChange >= 0 ? '+' : '') +
                                          timeframePriceChange.toFixed(2)
                                    }%
                                </span>
                            </div>

                            <div className="relative" style={{ height: '200px' }}>
                                {/* Timeframe selector */}
                                <div className="absolute right-0" style={{ top: '10px', zIndex: 10 }}>
                                    <div className="flex space-x-2">
                                        {TIMEFRAME_OPTIONS.map(option => (
                                            <button
                                                key={option.value}
                                                onClick={() => setSelectedTimeframe(option.value)}
                                                className={`px-2 py-1 text-sm rounded ${
                                                    selectedTimeframe === option.value
                                                        ? 'bg-myColor-600 dark:bg-zinc-600 text-white'
                                                        : 'text-myColor-600 dark:text-zinc-400 hover:bg-myColor-100 dark:hover:bg-zinc-700'
                                                }`}
                                            >
                                                {option.label}
                                            </button>
                                        ))}
                                    </div>
                                </div>

                                {/* Chart container with proper dimensions */}
                                <div className="relative" style={{ width: '100%', height: '200px' }}>
                                    <svg 
                                        width="100%" 
                                        height="100%" 
                                        viewBox={`0 0 ${width} ${height}`}
                                        preserveAspectRatio="xMidYMid meet"
                                    >
                                        <defs>
                                            <linearGradient 
                                                id="areaGradient" 
                                                x1="0" 
                                                y1="0" 
                                                x2="0" 
                                                y2="1"
                                            >
                                                <stop 
                                                    offset="0%" 
                                                    stopColor="#8656b5" 
                                                    stopOpacity="0.3" 
                                                />
                                                <stop 
                                                    offset="100%" 
                                                    stopColor="#8656b5" 
                                                    stopOpacity="0" 
                                                />
                                            </linearGradient>
                                        </defs>
                                        
                                        <Group left={margin.left} top={margin.top}>
                                            {/* Area gradient under the line */}
                                            <path
                                                d={`
                                                    M ${priceHistory.map(d => `${xScale(new Date(d.timestamp).getTime())},${yScale(d.price)}`).join(' L ')}
                                                    L ${xScale(new Date(priceHistory[priceHistory.length - 1]?.timestamp).getTime())},${yMax}
                                                    L ${xScale(new Date(priceHistory[0]?.timestamp).getTime())},${yMax}
                                                    Z
                                                `}
                                                fill="url(#areaGradient)"
                                            />

                                            {/* Line path */}
                                            <path
                                                d={`M ${priceHistory.map(d => `${xScale(new Date(d.timestamp).getTime())},${yScale(d.price)}`).join(' L ')}`}
                                                stroke={gradientColors.line}
                                                strokeWidth={2}
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />

                                            {/* Axes with improved visibility */}
                                            <AxisBottom
                                                top={yMax}
                                                scale={xScale}
                                                stroke={isDarkMode ? '#374151' : '#e5e7eb'}
                                                tickStroke={isDarkMode ? '#374151' : '#e5e7eb'}
                                                tickFormat={formatDate}
                                                numTicks={5}
                                                tickLabelProps={() => ({
                                                    fill: isDarkMode ? '#9ca3af' : '#6b7280',
                                                    fontSize: 10,
                                                    textAnchor: 'middle',
                                                    dy: '0.75em'
                                                })}
                                            />
                                            <AxisLeft
                                                scale={yScale}
                                                stroke={isDarkMode ? '#374151' : '#e5e7eb'}
                                                tickStroke="transparent"
                                                tickFormat={(value) => `$${value.toLocaleString()}`}
                                                numTicks={5}
                                                tickLabelProps={() => ({
                                                    fill: isDarkMode ? '#9ca3af' : '#6b7280',
                                                    fontSize: 10,
                                                    textAnchor: 'end',
                                                    dx: '-0.5em',
                                                })}
                                            />

                                            {/* Hover overlay */}
                                            <rect
                                                width={xMax}
                                                height={yMax}
                                                fill="transparent"
                                                onTouchStart={handleMouseMove}
                                                onTouchMove={handleMouseMove}
                                                onMouseMove={handleMouseMove}
                                                onMouseLeave={() => hideTooltip()}
                                            />

                                            {/* Highlight point */}
                                            {tooltipData && (
                                                <circle
                                                    cx={xScale(new Date(tooltipData.timestamp).getTime())}
                                                    cy={yScale(tooltipData.price)}
                                                    r={4}
                                                    fill={gradientColors.line}
                                                    stroke="white"
                                                    strokeWidth={2}
                                                />
                                            )}
                                        </Group>
                                    </svg>

                                    {/* Tooltip */}
                                    {tooltipData && (
                                        <TooltipWithBounds
                                            key={Math.random()}
                                            top={tooltipTop}
                                            left={tooltipLeft}
                                            className="bg-white dark:bg-zinc-800 p-2 rounded shadow-lg"
                                        >
                                            <div className="text-sm">
                                                <div className="font-semibold">
                                                    ${tooltipData.price.toLocaleString(undefined, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })}
                                                </div>
                                                <div className="text-gray-500 dark:text-gray-400">
                                                    {formatDate(new Date(tooltipData.timestamp))}
                                                </div>
                                            </div>
                                        </TooltipWithBounds>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex justify-center items-center h-[240px]">
                            <span className="text-gray-500 dark:text-gray-400">
                                {priceHistory.length === 0 ? 'No data available' : 'Loading...'}
                            </span>
                        </div>
                    )}
                </div>
            </div>

            {/* Second column - market overview with top movers */}
            <div className="lg:col-span-2 dark:bg-zinc-800/50 bg-white/50 rounded-lg shadow p-6 relative overflow-hidden">
                {/* Dotted background with gradient */}
                <div className="absolute inset-0">
                    <div className="absolute inset-0"
                        style={{ 
                            backgroundImage: 'radial-gradient(#000 1px, transparent 1px)',
                            backgroundSize: '16px 16px',
                            opacity: 0.05,
                            maskImage: 'linear-gradient(to right bottom, black -120%, transparent)',
                            WebkitMaskImage: 'linear-gradient(to right bottom, black -120%, transparent)'
                        }}>
                    </div>
                </div>

                {/* Content layer */}
                <div className="relative">
                    {/* Grid container for winners and losers */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Top Gainers */}
                        <div>
                            <h3 className="text-lg font-semibold mb-4 text-green-600 dark:text-green-400">
                                Biggest Winners (24h)
                            </h3>
                            <div className="space-y-3">
                                {topMovers.gainers.map((coin) => (
                                    <div key={coin.symbol} className="flex items-center justify-between py-1 px-6 bg-white/50 dark:bg-zinc-700/50 backdrop-blur-sm rounded-lg shadow-sm">
                                        <div className="flex items-center space-x-3">
                                            <img
                                                src={`/icon/black/${coin.symbol.toLowerCase()}.svg`}
                                                alt={coin.symbol}
                                                className="w-8 h-8 rounded-full dark:invert"
                                                onError={(e) => {
                                                    e.currentTarget.style.display = 'none';
                                                }}
                                            />
                                            <div>
                                                <div className="font-medium text-myColor-800 dark:text-zinc-300">
                                                    {coin.name} ({coin.symbol})
                                                </div>
                                                <div className="text-sm text-gray-500 dark:text-gray-400">
                                                    Vol: {formatNumber(coin.volume_24h)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <div className="font-semibold text-myColor-800 dark:text-zinc-300">
                                                {formatNumber(coin.price_usd)}
                                            </div>
                                            <div className="text-green-600 dark:text-green-400">
                                                {formatPercentage(coin.price_change_24h_percent)}%
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Top Losers */}
                        <div>
                            <h3 className="text-lg font-semibold mb-4 text-red-600 dark:text-red-400">
                                Biggest Losers (24h)
                            </h3>
                            <div className="space-y-3">
                                {topMovers.losers.map((coin) => (
                                    <div key={coin.symbol} className="flex items-center justify-between py-1 px-6 bg-white/50 dark:bg-zinc-700/50 backdrop-blur-sm rounded-lg shadow-sm">
                                        <div className="flex items-center space-x-3">
                                            <img
                                                src={`/icon/black/${coin.symbol.toLowerCase()}.svg`}
                                                alt={coin.symbol}
                                                className="w-8 h-8 rounded-full dark:invert"
                                                onError={(e) => {
                                                    e.currentTarget.style.display = 'none';
                                                }}
                                            />
                                            <div>
                                                <div className="font-medium text-myColor-800 dark:text-zinc-300">
                                                    {coin.name} ({coin.symbol})
                                                </div>
                                                <div className="text-sm text-gray-500 dark:text-gray-400">
                                                    Vol: {formatNumber(coin.volume_24h)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <div className="font-semibold text-myColor-800 dark:text-zinc-300">
                                                {formatNumber(coin.price_usd)}
                                            </div>
                                            <div className="text-red-600 dark:text-red-400">
                                                {formatPercentage(coin.price_change_24h_percent)}%
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarketOverview; 