import React, { createContext, useContext, useState, useEffect } from 'react';
import axios, { AxiosInstance } from 'axios';

interface AuthContextType {
    isAuthenticated: boolean;
    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
    api: AxiosInstance;
    user: {
        isAdmin: boolean;
        // other user properties...
    } | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
        return !!localStorage.getItem('token');
    });

    const [axiosInstance] = useState(() => {
        const instance = axios.create({
            baseURL: API_URL,
            headers: {
                'Content-Type': 'application/json'
            },
            timeout: 5000,
            withCredentials: true
        });

        // Add interceptors
        instance.interceptors.request.use((config) => {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });

        instance.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response?.status === 401) {
                    localStorage.removeItem('token');
                    setIsAuthenticated(false);
                }
                return Promise.reject(error);
            }
        );

        return instance;
    });

    const login = async (username: string, password: string) => {
        try {
            console.log('Attempting to connect to:', API_URL);
            const response = await axiosInstance.post('/api/auth/login', { 
                username, 
                password 
            });
            
            console.log('Login response:', response.data);
            
            const { token, user } = response.data;
            if (!token) {
                throw new Error('No token received from server');
            }
            
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            setIsAuthenticated(true);
        } catch (error: any) {
            console.error('Login error details:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status
            });

            if (error.code === 'ERR_NETWORK') {
                throw new Error('Cannot connect to server. Please check if the server is running.');
            }
            if (error.response?.data?.message) {
                throw new Error(error.response.data.message);
            }
            throw new Error('Login failed. Please try again.');
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
    };

    // Check token validity on mount
    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    await axiosInstance.get('/api/auth/verify');
                    setIsAuthenticated(true);
                } catch (error) {
                    logout();
                }
            }
        };
        checkAuth();
    }, [axiosInstance]);

    const contextValue: AuthContextType = {
        isAuthenticated,
        login,
        logout,
        api: axiosInstance,
        user: null
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}; 