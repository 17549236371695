import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { useAuth } from '../contexts/AuthContext';

interface Article {
  id: number;
  title: string;
  content: string;
  url: string;
  image_url: string | null;
  published_at: string;
  publisher_name: string;
  publisher?: {
    name: string;
    logo_url: string | null;
  };
  freshness_score: number | null;
  sentiment_score: string | null;
  overview: string | null;
  tags?: Array<{ id: number; name: string }>;
  ad?: boolean;
  featured?: boolean;
}

interface ArticleDetailModalProps {
  article: Article | null;
  onClose: () => void;
  onDelete: (id: number) => void;
}

const ArticleDetailModal: React.FC<ArticleDetailModalProps> = ({ article, onClose, onDelete }) => {
  const [showFullContent, setShowFullContent] = useState(false);
  const { api } = useAuth();

  if (!article) return null;

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this article?')) {
      try {
        await onDelete(article.id);
        console.log('Article deleted successfully.');
        onClose();
      } catch (error: any) {
        console.error('Failed to delete the article:', error);
      }
    }
  };

  const handleAnalyze = async () => {
    try {
      const response = await api.post(`/api/ai/analyze/${article.id}`);
      console.log('AI Analysis Response:', response.data);
      // Optionally, refresh or update the article data if needed
    } catch (error: any) {
      console.error('Error analyzing article:', error);
    }
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
          <div className="flex justify-between items-start mb-4">
            <h2 className="text-xl font-bold">{article.title}</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700" aria-label="Close Modal">
              <span className="sr-only">Close</span>
              ×
            </button>
          </div>
          
          <div className="space-y-4">
            {article.image_url && (
              <img src={article.image_url} alt={article.title} className="w-full h-48 object-cover rounded" />
            )}
            
            <div className="flex items-center space-x-2">
              <span className="font-semibold">Publisher:</span>
              <span>{article.publisher?.name || 'Unknown Publisher'}</span>
            </div>
            
            <div className="flex items-center space-x-2">
              <span className="font-semibold">Published:</span>
              <span>{format(new Date(article.published_at), 'PPP')}</span>
            </div>
            
            {article.tags && Array.isArray(article.tags) && article.tags.length > 0 ? (
                <div className="flex flex-wrap gap-2">
                    {article.tags.map(tag => (
                        <span key={tag.id} className="px-2 py-1 bg-gray-100 rounded-full text-sm">
                            {tag.name}
                        </span>
                    ))}
                </div>
            ) : (
                <div className="text-sm text-gray-400">No tags</div>
            )}

            {article.freshness_score != null && (
              <div className="flex items-center space-x-2">
                <span className="font-semibold">Score:</span>
                <span>{Number(article.freshness_score).toFixed(2)}</span>
              </div>
            )}

            {article.sentiment_score && (
              <div className="flex items-center space-x-2">
                <span className="font-semibold">Sentiment:</span>
                <span>{article.sentiment_score}</span>
              </div>
            )}
            
            {article.overview && (
              <div>
                <h3 className="font-semibold">Overview:</h3>
                <p>{article.overview}</p>
              </div>
            )}
          </div>
          
          <div className="flex justify-end space-x-4 mt-6">
            <button
              onClick={handleAnalyze}
              className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
              aria-label={`Analyze ${article.title} with AI`}
            >
              Analyze with AI
            </button>
            <button
              onClick={handleDelete}
              className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
              aria-label="Delete Article"
            >
              Delete Article
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const ArticlesManagement: React.FC = () => {
  const { api } = useAuth();
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);
  const [analyzing, setAnalyzing] = useState<number | null>(null);
  const [isDeleting, setIsDeleting] = useState<number | null>(null);
  const [filters, setFilters] = useState<{
    publisher: string;
    tag: string;
    dateFrom: string;
    dateTo: string;
  }>({
    publisher: '',
    tag: '',
    dateFrom: '',
    dateTo: '',
  });

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        const response = await api.get('/api/articles/admin', { params: filters });
        setArticles(response.data || []);
        setError('');
      } catch (err: any) {
        console.error('Error fetching articles:', err);
        setError('Failed to fetch articles.');
        setArticles([]);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [api, filters]);

  const handleDelete = async (id: number) => {
    try {
      setIsDeleting(id);
      await api.delete(`/api/articles/${id}`);
      setArticles(articles.filter(article => article.id !== id));
      console.log('Article deleted successfully.');
      setError('');
    } catch (error: any) {
      console.error('Error deleting article:', error);
      setError('Failed to delete article. Please try again.');
    } finally {
      setIsDeleting(null);
    }
  };

  const handleToggleFeatured = async (id: number, featured: boolean) => {
    try {
      await api.patch(`/articles/${id}/featured`, { featured: !featured });
      setArticles(articles.map(article => 
        article.id === id ? { ...article, featured: !featured } : article
      ));
      console.log(`Article ${id} featured status toggled to ${!featured}.`);
    } catch (error) {
      console.error('Error updating article:', error);
    }
  };

  const handleAnalyze = async (id: number) => {
    try {
      setAnalyzing(id);
      const response = await api.post(`/api/ai/analyze/${id}`);
      console.log('AI Analysis Response:', response.data);

      const { freshness, sentiment, tags, overview } = response.data;
      
      const formattedTags = Array.isArray(tags) 
          ? tags.map((tag: string) => ({ id: Date.now(), name: tag }))
          : [];

      if (freshness !== undefined && sentiment !== undefined && tags !== undefined && overview !== undefined) {
          console.log('AI analysis completed successfully.');
          setArticles(prevArticles =>
              prevArticles.map(article =>
                  article.id === id 
                      ? { 
                          ...article, 
                          freshness_score: freshness,
                          sentiment_score: sentiment,
                          overview,
                          tags: formattedTags
                      } 
                      : article
              )
          );
      } else {
          throw new Error('Incomplete analysis data received.');
      }
    } catch (error: any) {
      console.error('Failed to analyze the article:', error);
    } finally {
      setAnalyzing(null);
    }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-64">Loading...</div>;
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Articles Management</h1>
      
      {/* Filters */}
      <div className="mb-6 grid grid-cols-1 md:grid-cols-4 gap-4">
        <input
          type="text"
          placeholder="Filter by publisher"
          className="border rounded p-2"
          value={filters.publisher}
          onChange={e => setFilters({ ...filters, publisher: e.target.value })}
        />
        <input
          type="text"
          placeholder="Filter by tag"
          className="border rounded p-2"
          value={filters.tag}
          onChange={e => setFilters({ ...filters, tag: e.target.value })}
        />
        <input
          type="date"
          className="border rounded p-2"
          value={filters.dateFrom}
          onChange={e => setFilters({ ...filters, dateFrom: e.target.value })}
        />
        <input
          type="date"
          className="border rounded p-2"
          value={filters.dateTo}
          onChange={e => setFilters({ ...filters, dateTo: e.target.value })}
        />
      </div>

      {/* Error Message */}
      {error && (
        <div className="mb-4 p-4 bg-red-100 text-red-700 rounded">
          {error}
        </div>
      )}

      {/* Articles Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border rounded-lg">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Publisher</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tags</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Published</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Score</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sentiment</th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Featured</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          {articles && articles.length > 0 ? (
            <tbody className="divide-y divide-gray-200">
                {articles.map(article => (
                    <tr key={article.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4">
                            <div className="text-sm font-medium text-gray-900">{article.title}</div>
                        </td>
                        <td className="px-6 py-4">
                            <div className="text-sm text-gray-500">
                                {article.publisher?.name || 'Unknown Publisher'}
                            </div>
                        </td>
                        <td className="px-6 py-4">
                            <div className="flex flex-wrap gap-1">
                                {Array.isArray(article.tags) ? (
                                    article.tags.map(tag => (
                                        <span key={tag.id} className="px-2 py-1 bg-gray-100 rounded-full text-xs">
                                            {tag.name}
                                        </span>
                                    ))
                                ) : (
                                    <span className="text-sm text-gray-400">No tags</span>
                                )}
                            </div>
                        </td>
                        <td className="px-6 py-4">
                            <div className="text-sm text-gray-500">
                                {format(new Date(article.published_at), 'PPP HH:mm')}
                            </div>
                        </td>
                        <td className="px-6 py-4">
                            {article.freshness_score != null ? (
                                <div className="text-sm text-gray-500">
                                    {Number(article.freshness_score).toFixed(2)}
                                </div>
                            ) : (
                                <div className="text-sm text-gray-400">-</div>
                            )}
                        </td>
                        <td className="px-6 py-4">
                            {article.sentiment_score ? (
                                <div className="text-sm text-gray-500">
                                    {article.sentiment_score}
                                </div>
                            ) : (
                                <div className="text-sm text-gray-400">-</div>
                            )}
                        </td>
                        <td className="px-6 py-4 text-center">
                            <button
                                onClick={() => handleToggleFeatured(article.id, article.featured || false)}
                                className={`px-3 py-1 rounded-full text-xs ${
                                    article.featured 
                                        ? 'bg-green-100 text-green-800' 
                                        : 'bg-gray-100 text-gray-800'
                                }`}
                                aria-label={article.featured ? 'Unmark as Featured' : 'Mark as Featured'}
                            >
                                {article.featured ? 'Featured' : 'Not Featured'}
                            </button>
                        </td>
                        <td className="px-6 py-4 space-x-2">
                            <button
                                onClick={() => setSelectedArticle(article)}
                                className="text-blue-600 hover:text-blue-900"
                                aria-label={`View details of ${article.title}`}
                            >
                                View Details
                            </button>
                            <button
                                onClick={() => handleAnalyze(article.id)}
                                className={`text-green-600 hover:text-green-900 ${analyzing === article.id ? 'cursor-not-allowed opacity-50' : ''}`}
                                disabled={analyzing === article.id}
                                aria-label={`Analyze ${article.title} with AI`}
                            >
                                {analyzing === article.id ? 'Analyzing...' : 'Analyze with AI'}
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
          ) : (
            <tbody>
                <tr>
                    <td colSpan={8} className="px-6 py-4 text-center text-gray-500">
                        {loading ? 'Loading...' : 'No articles found'}
                    </td>
                </tr>
            </tbody>
          )}
        </table>
      </div>

      {selectedArticle && (
        <ArticleDetailModal
          article={selectedArticle}
          onClose={() => setSelectedArticle(null)}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default ArticlesManagement; 