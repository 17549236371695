import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import NewsTable from '../news/NewsTable';
import MarketOverview from '../widgets/MarketOverview';
import { Article } from '../../types/Article';
import SEOHead from '../SEO/SEOHead';
import NewsSearch from '../news/NewsSearch';
import NewsFiltersPopup from '../news/NewsFiltersPopup';
import { Filter, LayoutGrid, Table } from 'lucide-react';
import { AnimatePresence } from 'framer-motion';
import FilterCard from '../news/FilterCard';
import NewsCard from '../news/NewsCard';
import { motion } from 'framer-motion';

const HomePage: React.FC = () => {
    const { api } = useAuth();
    const [news, setNews] = useState<Article[]>([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [filters, setFilters] = useState({
        publisher: '',
        tag: '',
        freshness_score: '',
        sentiment_score: '',
        no_ads: true,
        category: ''
    });
    const [pageData, setPageData] = useState<any>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [categories, setCategories] = useState<Array<{ label: string; value: string }>>([]);
    const [viewMode, setViewMode] = useState<'table' | 'grid'>('table');

    const fetchNews = useCallback(async (pageNum: number, currentFilters = filters) => {
        try {
            setLoading(true);
            const response = await api.get('/api/articles', {
                params: {
                    page: pageNum,
                    limit: 20,
                    search: searchQuery,
                    ...currentFilters
                }
            });

            const { articles, hasMore: more, nextPage, totalCount } = response.data;
            
            if (pageNum === 1) {
                setNews(articles);
            } else {
                setNews(prev => [...prev, ...articles]);
            }
            
            setHasMore(more);
            setPage(nextPage);
        } catch (error) {
            console.error('Error fetching news:', error);
        } finally {
            setLoading(false);
        }
    }, [api, searchQuery, filters]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setPage(1);
            fetchNews(1, filters);
        }, 300);

        return () => clearTimeout(timer);
    }, [searchQuery, filters]);

    useEffect(() => {
        fetchNews(1, filters);
    }, []);

    useEffect(() => {
        const fetchPageData = async () => {
            try {
                const response = await api.get('/api/pages/home');
                setPageData(response.data);
            } catch (error) {
                console.error('Failed to fetch page data:', error);
            }
        };
        fetchPageData();
    }, [api]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await api.get('/api/categories');
                setCategories(response.data.map((cat: any) => ({
                    label: cat.name,
                    value: cat.slug
                })));
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, [api]);

    const handleFilterChange = (key: string, value: any) => {
        setFilters(prevFilters => {
            const newFilters = { ...prevFilters, [key]: value };
            setPage(1);
            fetchNews(1, newFilters);
            return newFilters;
        });
    };

    const handleLoadMore = useCallback(() => {
        if (!loading && hasMore) {
            fetchNews(page, filters);
        }
    }, [loading, hasMore, page, filters, fetchNews]);

    const getFilterLabel = (key: string, value: string | boolean) => {
        switch (key) {
            case 'freshness_score':
                return 'Freshness';
            case 'sentiment_score':
                return 'Sentiment';
            case 'category':
                const category = categories.find(c => c.value === value);
                return category ? 'Category' : '';
            case 'no_ads':
                return 'No sponsored content';
            default:
                return key.charAt(0).toUpperCase() + key.slice(1);
        }
    };

    return (
        <>
            <SEOHead
                title={pageData?.meta_title || 'Crypto News'}
                description={pageData?.meta_description || 'Latest cryptocurrency news and updates'}
                image={pageData?.og_image || undefined}
                canonical="/"
            />
            <div className="space-y-6">
                 <MarketOverview />
                {/* Market Overview Widget */}
                {/* <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                    <div className="dark:bg-zinc-800 bg-whitelg:col-span-2">
                    <MarketOverview />
                    </div>
                    <div className="dark:bg-zinc-800 bg-white rounded-lg shadow p-6">
    <h2 className="text-xl font-semibold mb-4">Trend Accuracy</h2>
    <div className="flex justify-center">
        <div className="relative w-48 h-48">
            <div className="absolute inset-0 flex items-center justify-center">
                <span className="text-2xl font-bold">80%</span>
            </div>
        </div>
    </div>
    </div>
                </div> */}

                {/* News Table Section */}
                <div className="">
                    <div className="p-6">
                        <h1 className="text-xl font-semibold mb-4">Latest Crypto News</h1>
                        <div className="flex flex-wrap items-center gap-3 mb-6">
                            <NewsSearch value={searchQuery} onChange={setSearchQuery} />
                            
                            <div className="flex flex-wrap items-center gap-2">
                                <AnimatePresence>
                                    {Object.entries(filters).map(([key, value]) => {
                                        if (!value) return null;
                                        return (
                                            <FilterCard
                                                key={key}
                                                label={getFilterLabel(key, value)}
                                                value={value}
                                                onRemove={() => handleFilterChange(key, '')}
                                            />
                                        );
                                    })}
                                </AnimatePresence>
                                
                                <div className="flex items-center gap-2">
                                    <button
                                        onClick={() => setViewMode(prev => prev === 'table' ? 'grid' : 'table')}
                                        className="p-2 rounded-lg
                                                 bg-myColor-100/50 dark:bg-zinc-800/50
                                                 hover:bg-myColor-200/50 dark:hover:bg-zinc-700/50
                                                 border border-myColor-400/50 dark:border-zinc-700/50
                                                 text-myColor-600 dark:text-zinc-400
                                                 transition-colors duration-200"
                                        title={viewMode === 'table' ? 'Switch to grid view' : 'Switch to table view'}
                                    >
                                        {viewMode === 'table' ? <LayoutGrid className="w-5 h-5" /> : <Table className="w-5 h-5" />}
                                    </button>
                                    <button
                                        onClick={() => setIsFiltersOpen(true)}
                                        className="p-2 rounded-lg
                                                 bg-myColor-100/50 dark:bg-zinc-800/50
                                                 hover:bg-myColor-200/50 dark:hover:bg-zinc-700/50
                                                 border border-myColor-400/50 dark:border-zinc-700/50
                                                 text-myColor-600 dark:text-zinc-400
                                                 transition-colors duration-200"
                                    >
                                        <Filter className="w-5 h-5" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {viewMode === 'table' ? (
                            <NewsTable
                                news={news}
                                loading={loading}
                                onLoadMore={handleLoadMore}
                                hasMore={hasMore}
                                filters={filters}
                                onFilterChange={handleFilterChange}
                            />
                        ) : (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                {news.map((item) => (
                                    <NewsCard key={item.id} item={item} />
                                ))}
                                {hasMore && (
                                    <div className="col-span-full mt-6 text-center">
                                        <motion.button
                                            onClick={handleLoadMore}
                                            disabled={loading}
                                            className="px-6 py-3 bg-myColor-800 dark:bg-zinc-800
                                                     text-white dark:text-zinc-100
                                                     rounded-lg hover:bg-myColor-900 dark:hover:bg-zinc-700
                                                     transition-colors duration-300 disabled:opacity-50"
                                            whileHover={{ scale: 1.02 }}
                                            whileTap={{ scale: 0.98 }}
                                        >
                                            {loading ? 'Loading...' : 'Load More'}
                                        </motion.button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <NewsFiltersPopup
                isOpen={isFiltersOpen}
                onClose={() => setIsFiltersOpen(false)}
                filters={filters}
                onFilterChange={handleFilterChange}
                categories={categories}
            />
        </>
    );
};

export default HomePage; 